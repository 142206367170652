import React, { Fragment } from 'react'
import Typography from '@material-ui/core/Typography'

export const ErrorPage = props => {
  if (props.errorType === 'notsspt') {
    return (
      <Fragment>
        <Typography variant='h5' align='left' color='primary'
          style={{ marginBottom: '1rem', marginTop: '3rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
          Tisztelt Ügyfelünk!
        </Typography>
        <Typography variant='subtitle1' align='left'
          style={{ marginBottom: '2rem', marginTop: '1rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
          Olyan oldalra navigált, amelyet a ticketing rendszer belső, SSPT-s feladatainak ellátására tartunk fent. Sajnos ide nem tudunk Önnek belépést biztosítani.
        </Typography>
        <Typography variant='h6' align='left' color='primary'
          style={{ marginBottom: '1rem', marginTop: '3rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
          Hogyan tovább?
        </Typography>
        <Typography variant='subtitle1' align='left'
          style={{ marginBottom: '0.5rem', marginTop: '1rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
          Ticketet szeretett volna nyitni?
          <a href={props.newTicketUrl}> Kattintson ide ticket megnyitásához!</a>
        </Typography>
        <Typography variant='subtitle1' align='left'
          style={{ marginBottom: '1rem', marginTop: '0.5rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
          Emberi segítséget szeretne? Hívjon minket:
          <a href='tel:+3612832426'> + 36 1 283 2426</a>
        </Typography>
        <Typography variant='subtitle1' align='left'
          style={{ marginBottom: '0.5rem', marginTop: '2rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
          Üdvözlettel:
        </Typography>
        <Typography variant='subtitle1' align='left'
          style={{ marginBottom: '1rem', marginTop: '0.5rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
          SSPT szerviz csapat
        </Typography>
      </Fragment>
    )
  } else {
    switch (props.errorType) {
      case 'maintenance':
        return (
          <Fragment>
            <Typography variant='h5' align='left' color='primary'
              style={{ marginBottom: '1rem', marginTop: '3rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              Tisztelt Ügyfelünk,
            </Typography>
            <Typography variant='subtitle1' align='left'
              style={{ marginBottom: '2rem', marginTop: '1rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              {`A Smart Solutions Papírtechnológia Kft. szerviz ticketing rendszere jelenleg karbantartás alatt áll. A rendszer várhatóan legkésőbb ${props.returnTime} lesz újra elérhető.`}
          </Typography>
            <Typography variant='subtitle1' align='left'
              style={{ marginBottom: '0rem', marginTop: '2rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              Amennyiben sürgős ügyben keresné a szervizt, kérem lépjen kapcsolatba velünk a szerviz ügyeleti telefonszámán.
          </Typography>
            <Typography variant='subtitle1' align='left'
              style={{ marginBottom: '0rem', marginTop: '2rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              Megértését és türelmét ezúton is nagyon köszönjük!
          </Typography>
            <Typography variant='subtitle1' align='left'
              style={{ marginBottom: '0rem', marginTop: '2rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              Üdvözlettel,
          </Typography>
            <Typography variant='subtitle1' align='left'
              style={{ marginBottom: '1rem', marginTop: '0rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              SSPT szerviz csapat
          </Typography>
          </Fragment>
        )
      case 'notteams':
        return (
          <Fragment>
            <Typography variant='h5' align='left' color='primary'
              style={{ marginBottom: '1rem', marginTop: '3rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              {`Szia ${props.authedUser.attributes.name.split(' ').slice(-1)},`}
            </Typography>
            <Typography variant='subtitle1' align='left'
              style={{ marginBottom: '2rem', marginTop: '1rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              Úgy néz ki, nem a Microsoft Teams felületről nyitottad meg az appot. Kérlek lépj ki innen, lépj be a Teams appba, és ott a megfelelő ticket csatornájából indítsd el az alkalmazást.
          </Typography>
            <Typography variant='subtitle1' align='left'
              style={{ marginBottom: '0rem', marginTop: '2rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              Üdvözlettel,
          </Typography>
            <Typography variant='subtitle1' align='left'
              style={{ marginBottom: '1rem', marginTop: '0rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              Ticketing Bot
          </Typography>
          </Fragment>
        )
      case 'notassigned':
        return (
          <Fragment>
            <Typography variant='h5' align='left' color='primary'
              style={{ marginBottom: '1rem', marginTop: '3rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              {`Szia ${props.authedUser.attributes.name.split(' ').slice(-1)},`}
            </Typography>
            <Typography variant='subtitle1' align='left'
              style={{ marginBottom: '2rem', marginTop: '1rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              Úgy néz ki, nem vagy hozzárendelve ehhez a tickethez, így nem indíthatsz innen eseményt. Kérlek lépj ki innen, és a megfelelő ticket csatornájából indítsd el az appot.
            </Typography>
            <Typography variant='subtitle1' align='left'
              style={{ marginBottom: '0rem', marginTop: '2rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              Üdvözlettel,
            </Typography>
            <Typography variant='subtitle1' align='left'
              style={{ marginBottom: '1rem', marginTop: '0rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              Ticketing Bot
            </Typography>
          </Fragment>
        )
      case 'notallowed':
        return (
          <Fragment>
            <Typography variant='h5' align='left' color='primary'
              style={{ marginBottom: '1rem', marginTop: '3rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              {`Szia ${props.authedUser.attributes.name.split(' ').slice(-1)},`}
            </Typography>
            <Typography variant='subtitle1' align='left'
              style={{ marginBottom: '2rem', marginTop: '1rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              ilyen eseményt nem indíthatsz ezen a ticketen. Ez jogosultsági beállítás és/vagy a ticket státusza miatt fordulhat elő. Kérlek lépj vissza, és próbálkozz más funkcióval.
            </Typography>
            <Typography variant='subtitle1' align='left'
              style={{ marginBottom: '0rem', marginTop: '2rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              Üdvözlettel,
            </Typography>
            <Typography variant='subtitle1' align='left'
              style={{ marginBottom: '1rem', marginTop: '0rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              Ticketing Bot
            </Typography>
          </Fragment>
        )
      case 'notadmin':
        return (
          <Fragment>
            <Typography variant='h5' align='left' color='primary'
              style={{ marginBottom: '1rem', marginTop: '3rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              {`Szia ${props.authedUser.attributes.name.split(' ').slice(-1)},`}
            </Typography>
            <Typography variant='subtitle1' align='left'
              style={{ marginBottom: '2rem', marginTop: '1rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              ez a funkció csak admin jogosultsággal használható. Kérlek válassz más funkciót a bal oldali menüből.
            </Typography>
            <Typography variant='subtitle1' align='left'
              style={{ marginBottom: '0rem', marginTop: '2rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              Üdvözlettel,
            </Typography>
            <Typography variant='subtitle1' align='left'
              style={{ marginBottom: '1rem', marginTop: '0rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              Ticketing Bot
            </Typography>
          </Fragment>
        )
      default:
        return (
          <Fragment>
            <Typography variant='h5' align='left' color='primary'
              style={{ marginBottom: '1rem', marginTop: '3rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              Ez az oldal nem található.
            </Typography>
            <Typography variant='subtitle1' align='left'
              style={{ marginBottom: '2rem', marginTop: '1rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              Kérem lépjen vissza, vagy keresse fel újból a megfelelő webhelyet.
            </Typography>
            <Typography variant='subtitle1' align='left'
              style={{ marginBottom: '0rem', marginTop: '2rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              Üdvözlettel,
            </Typography>
            <Typography variant='subtitle1' align='left'
              style={{ marginBottom: '1rem', marginTop: '0rem', paddingLeft: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              SSPT Ticketing
            </Typography>
          </Fragment>
        )
    }
  }
}