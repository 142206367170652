import React from 'react'
import Popover from '@material-ui/core/Popover'
import { SignOutButton } from './SignOutButton'

import Typography from '@material-ui/core/Typography'

export const AccountPopover = props => {
  return (
    <Popover
      open={Boolean(props.anchorEl)}
      onClose={props.onClose}
      anchorReference="anchorEl"
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', justifyItems: 'center' }}>
        <Typography
          variant='subtitle1'
          align='center'
          color='textPrimary'
          style={{ marginTop: '1rem', fontWeight: 'bold', marginLeft: '2rem', marginRight: '2rem' }}
        >
          {props.authedUser.attributes.name}
        </Typography>
        <Typography
          variant='subtitle1'
          align='center'
          color='textPrimary'
          style={{ marginTop: '0.25rem', fontStyle: 'italic', marginLeft: '2rem', marginRight: '2rem' }}
        >
          {props.userCo}
        </Typography>
        <SignOutButton />
      </div>
    </Popover>
  )
}