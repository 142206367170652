import React, { useEffect, useState, Fragment, useContext } from 'react'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Alert from '@material-ui/lab/Alert'
import Fab from '@material-ui/core/Fab'
import Box from '@material-ui/core/Box'

import BeenhereIcon from '@material-ui/icons/Beenhere'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import red from '@material-ui/core/colors/red'

import { TicketEventCard } from './Subcomponents/TicketEventCard'
import { UploadProgress } from './Subcomponents/UploadProgress'

import { getFormattedlocalDate } from './helper/getFormattedLocalDate'
import { useHistory, useParams } from 'react-router-dom'
import { compareDateTimeStrings } from './helper/compareDateTimeStrings'

import { TicketingContext } from '../TicketingContext'
import { generatePayload } from './helper/generatePayload'


export const Ticket = () => {

  const params = useParams()
  const history = useHistory()

  const { ticketId } = params
  const { submitEvent, errorType, setErrorType, userCo, approver, setLoadingSuccess, refreshTicketDetails, ticketDetails, tickets, authedUser, classes, theme } = useContext(TicketingContext)

  const [upLoading, setUpLoading] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [ticketStatusMessage, setTicketStatusMessage] = useState('')
  const [ticketMessageError, setTicketMessageError] = useState(false)
  const [rejectIntention, setRejectIntention] = useState(false)
  const [closingAllowed, setClosingAllowed] = useState(false)
  const [fetchActive, setFetchActive] = useState(false)

  useEffect(() => {
    if (errorType) {
      setLoadingSuccess(true)
    }
  }, [errorType, setLoadingSuccess])

  useEffect(() => {
    try {
      if (!errorType && !ticketDetails[ticketId] && !fetchActive) {
        setFetchActive(true)
        refreshTicketDetails(ticketId)
      }
    } catch (error) {
      setErrorType(error.message)
    }
  }, [ticketDetails, ticketId, fetchActive, refreshTicketDetails, setErrorType, errorType])

  useEffect(() => {
    if (ticketDetails[ticketId] && fetchActive) {
      setFetchActive(false)
    }
  }, [ticketDetails, ticketId, fetchActive])

  useEffect(() => {
    if (ticketDetails[ticketId] && ['PENDING_CLOSE', 'PENDING_CANCEL'].includes(ticketDetails[ticketId].ticketData.ticketStatus)) {
      if (approver && (
        (!ticketDetails[ticketId].ticketData.clientApproval && userCo === ticketDetails[ticketId].ticketData.clientId) ||
        (!ticketDetails[ticketId].ticketData.ssptApproval && userCo === 'SSPT'))) {
        setClosingAllowed(true)
      } else {
        setClosingAllowed(false)
      }
    } else {
      setClosingAllowed(false)
    }
  }, [ticketDetails, ticketId, userCo, approver, authedUser.attributes])

  useEffect(() => {
    if (rejectIntention && (ticketStatusMessage === '' || ticketStatusMessage.split(' ').length < 3 || ticketStatusMessage.length < 15)) setTicketMessageError(true)
    else setTicketMessageError(false)
  }, [ticketStatusMessage, rejectIntention])

  const handleReject = async (eventType, ticketStatus) => {
    try {
      if (!(ticketStatusMessage === '' || ticketStatusMessage.split(' ').length < 3 || ticketStatusMessage.length < 15)) {
        setFormSubmitted(true)
        setUpLoading(true)
        const payLoad = generatePayload({ eventType, ticketId })
        payLoad.ticketStatus = ticketStatus
        payLoad.ticketStatusMessage = ticketStatusMessage
        await submitEvent({ payLoad, ticketId, setUpLoading, history })
        setTimeout(() => {
          setFormSubmitted(false)
          if (tickets instanceof Array) {
            history.goBack()
          }
        }, 1500)
      } else {
        setRejectIntention(true)
      }
    } catch (error) {
      console.log(error)
      setErrorType(error.message)
      setUpLoading(false)
    }
  }

  const handleApprove = async (eventType) => {
    try {
      setFormSubmitted(true)
      setUpLoading(true)
      const payLoad = generatePayload({ eventType, ticketId })
      if (userCo !== 'SSPT') {
        // In the client case, only clientApproval is possible
        payLoad.clientApproval = true
      } else {
        // In the SSPT case, the requestor can send in a clientApproval first and then send in the SSPT approval; others can only send in SSPT approval
        if (ticketDetails[ticketId].ticketData.requestorEmail === authedUser.attributes.email && !ticketDetails[ticketId].ticketData.clientApproval) {
          payLoad.clientApproval = true
        } else {
          payLoad.ssptApproval = true
        }
      }
      
      if (ticketStatusMessage) payLoad.ticketStatusMessage = ticketStatusMessage
      await submitEvent({ payLoad, ticketId, setUpLoading, history })
      setTimeout(() => {
        setFormSubmitted(false)
        if (tickets instanceof Array) {
          history.goBack()
        }
      }, 1500)
    } catch (error) {
      console.log(error)
      setErrorType(error.message)
      setUpLoading(false)
    }
  }

  return (
    <Fragment>
      { (formSubmitted || errorType) &&
        <UploadProgress upLoading={upLoading} errorType={errorType} setErrorType={setErrorType} setFormSubmitted={setFormSubmitted} classes={classes} theme={theme} />
      }
      { authedUser && ticketDetails[ticketId] &&
        <Grid container direction='column' justify='center' alignItems='center'>
          <Typography variant='h6' align='center' color='primary' style={{ marginBottom: '0rem', marginTop: '1rem', fontWeight: 'bold' }}>{ticketId}</Typography>
          <Typography variant='body1' align='center' color='textPrimary' style={{ marginBottom: '1rem', marginTop: '0rem' }}>Ticket események</Typography>
          {ticketDetails[ticketId] && [ticketDetails[ticketId].ticketData, ...ticketDetails[ticketId].ticketEvents].map((ticketEvent, index, array) => {
            if (!array[index - 1] || compareDateTimeStrings(array[index - 1].dateTime, ticketEvent.dateTime)) {
              return (
                <Fragment key={index}>
                  <Typography
                    variant='subtitle1'
                    align='center'
                    color='secondary'
                    style={{ marginBottom: '0.5rem', marginTop: '1rem', fontStyle: 'bold' }}>
                    {getFormattedlocalDate(ticketEvent.dateTime)}
                  </Typography>
                  <TicketEventCard ticketEvent={ticketEvent} classes={classes} theme={theme} authedUser={authedUser} />
                </Fragment>
              )
            } else return <TicketEventCard key={index} ticketEvent={ticketEvent} classes={classes} theme={theme} authedUser={authedUser} />
          })
          }
          {userCo === "SSPT" && ticketDetails[ticketId] &&
            ticketDetails[ticketId].ticketData &&
            (ticketDetails[ticketId].options.events.length > 0 ||
              ticketDetails[ticketId].options.statusOptions.length > 0) &&
            !['PENDING_CLOSE', 'PENDING_CANCEL'].includes(ticketDetails[ticketId].ticketData.ticketStatus) &&
            <Fragment>
              <Box height={80} width='100%' />
              <Fab
                style={{ position: 'fixed', bottom: theme.spacing(4) }}
                variant='extended'
                color='primary'
                onClick={() => history.push(`/ticketek/${ticketId}/events`)}
              >
                Ticketesemény indítása
            </Fab>
            </Fragment>
          }
          {closingAllowed &&
            <Fragment>
              <Grid item xs={12} sm={12} md={12} style={{ width: '80%', marginTop: '1rem' }}>
                <TextField
                  error={rejectIntention && ticketMessageError}
                  multiline
                  rows={3}
                  rowsMax={10}
                  disabled={formSubmitted}
                  fullWidth
                  value={ticketStatusMessage}
                  onChange={event => {
                    setTicketStatusMessage(event.target.value)
                  }}
                  label="Megjegyzés"
                  variant="filled"
                />
                {ticketMessageError && <Alert severity="error">Visszautasítás esetén mindenképpen töltse ki a megjegyzés mezőt!</Alert>}
              </Grid>
              <Grid container direction='row' justify='center' alignItems='center' spacing={2} style={{ marginTop: '2rem', marginBottom: '3rem' }}>
                <Grid item xs={10} md={5} style={{ textAlign: 'center', color: red[900] }}>
                  <Button
                    variant='outlined'
                    color='inherit'
                    fullWidth
                    disabled={ticketMessageError}
                    startIcon={<NotInterestedIcon />}
                    onClick={() => {
                      let eventType, ticketStatus
                      switch (ticketDetails[ticketId].ticketData.ticketStatus) {
                        case 'PENDING_CLOSE':
                          eventType = 'REJECT_CLOSE'
                          ticketStatus = 'REJECTED_CLOSE'
                          break
                        case 'PENDING_CANCEL':
                          eventType = 'REJECT_CANCEL'
                          ticketStatus = 'REJECTED_CANCEL'
                          break
                        default:
                          throw new Error('Encountered undefined ticketStatus for this functionality. Please revise.')
                      }
                      handleReject(eventType, ticketStatus)
                    }}
                  >{ticketDetails[ticketId].ticketData.ticketStatus === 'PENDING_CLOSE' ? 'Zárás' : 'Lemondás'} elutasítása
                  </Button>
                </Grid>
                <Grid item xs={10} md={5} style={{ textAlign: 'center' }}>
                  <Button
                    variant='contained'
                    color='primary'
                    fullWidth
                    startIcon={<BeenhereIcon />}
                    onClick={() => {
                      let eventType
                      switch (ticketDetails[ticketId].ticketData.ticketStatus) {
                        case 'PENDING_CLOSE':
                          eventType = 'APPROVE_CLOSE'
                          break
                        case 'PENDING_CANCEL':
                          eventType = 'APPROVE_CANCEL'
                          break
                        default:
                          throw new Error('Encountered undefined ticketStatus for this functionality. Please revise.')
                      }
                      handleApprove(eventType)
                    }}
                  >{ticketDetails[ticketId].ticketData.ticketStatus === 'PENDING_CLOSE' ? 'Zárás' : 'Lemondás'} jóváhagyása
                  </Button>
                </Grid>
              </Grid>
            </Fragment>
          }
        </Grid>
      }
    </Fragment>
  )
}