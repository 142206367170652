import React, { useEffect, Fragment, useState, useContext } from 'react'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import AnnouncementOutlinedIcon from '@material-ui/icons/AnnouncementOutlined'
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'
import BeenhereOutlinedIcon from '@material-ui/icons/BeenhereOutlined'
import NotInterestedOutlinedIcon from '@material-ui/icons/NotInterestedOutlined'
import HourglassEmptyOutlinedIcon from '@material-ui/icons/HourglassEmptyOutlined';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined'

import { TicketCard } from './Subcomponents/TicketCard'
import { getStatusName } from './helper/getStatusName'
import { TicketingContext } from '../TicketingContext'

const statusMap = {
  open: {
    'OPEN': true,
    'PLANNED': true,
    'REOPENED': true
  },
  confirmed: {
    'CONFIRMED': true
  },
  in_progress: {
    'IN_PROGRESS': true
  },
  waiting: {
    'WAITING_FOR_PARTS': true,
    'TO_BE_FINALIZED': true,
    'WAITING_FOR_OEM_SUPPORT': true
  },
  rejected: {
    'REJECTED_CLOSE': true,
    'REJECTED_CANCEL': true
  },
  approve: {
    'PENDING_CLOSE': true,
    'PENDING_CANCEL': true
  }
}

export const Tickets = props => {

  const { errorType, setErrorType, setLoadingSuccess, ticketCounts, setLastStatusSelection, refreshTickets, authedUser, lastStatusSelection, tickets, classes, theme } = useContext(TicketingContext)

  const [fetchActive, setFetchActive] = useState(false)

  useEffect(() => {
    if (errorType) {
      setLoadingSuccess(true)
    }
  }, [errorType, setLoadingSuccess])

  useEffect(() => {
    try {
      if (!tickets && !fetchActive) {
        setFetchActive(true)
        refreshTickets()
      }
    } catch (error) {
      setErrorType(error.message)
    }
  }, [tickets, fetchActive, refreshTickets, setErrorType])

  useEffect(() => {
    if (tickets && fetchActive) {
      setFetchActive(false)
    }
  }, [tickets, fetchActive])

  useEffect(() => {
    if (tickets && tickets.length > 0 && ticketCounts && ticketCounts[lastStatusSelection] === 0) {
      setLastStatusSelection(Object.keys(ticketCounts).reduce((statusKey1, statusKey2) => {
        if (ticketCounts[statusKey1] > ticketCounts[statusKey2]) return statusKey1
        else return statusKey2
      }))
    }
  }, [ticketCounts, lastStatusSelection, setLastStatusSelection, tickets])

  const handleTabChange = (event, newValue) => {
    setLastStatusSelection(newValue)
  }

  const matchesL = useMediaQuery(theme.breakpoints.up('lg'))

  return (
    <Fragment>
      <Grid container direction='row' justify='center' alignItems='center' style={{ marginTop: '0rem' }}>
        <div style={{ height: 100, width: '100%' }} />
        {tickets && tickets.length === 0 &&
          <Grid container direction='column' justify='flex-start' align='left' item xs={10}>
            <Typography variant='h5' align='left' color='primary'
              style={{ marginBottom: '1rem', marginTop: '3rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              {`Kedves ${authedUser.attributes.name.split(' ').slice(-1)},`}
            </Typography>
            <Typography variant='subtitle1' align='left'
              style={{ marginBottom: '0rem', marginTop: '1rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              Jelenleg nem található lezáratlan ticket a rendszerben.
          </Typography>
            <Typography variant='subtitle1' align='left'
              style={{ marginBottom: '0rem', marginTop: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              Üdvözlettel,
          </Typography>
            <Typography variant='subtitle1' align='left'
              style={{ marginBottom: '1rem', marginTop: '0rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              Smart Solutions Kft. Ticketing
          </Typography>
          </Grid>
        }
        {tickets && tickets.length !== 0 &&
          <Paper square elevation={4} style={{ width: '100%', marginBottom: '2rem', position: 'fixed', top: 45, zIndex: 100, paddingTop: 10 }}>
            {!matchesL &&
              <Typography variant='body2' color='primary'>{`${getStatusName(lastStatusSelection)} (${ticketCounts[lastStatusSelection]})`}</Typography>
            }
            <Tabs
              value={lastStatusSelection}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
              variant='fullWidth'
              centered
            >
              <Tab style={{ minWidth: 60 }} label={<div><div style={{ height: !matchesL && 24 }}><AnnouncementOutlinedIcon fontSize='default' color='inherit' /></div><div style={{ fontSize: 12 }}>{matchesL ? `Nyitott (${ticketCounts.open})` : null}</div></div>} value='open' disabled={ticketCounts.open === 0} />
              <Tab style={{ minWidth: 60 }} label={<div><div style={{ height: !matchesL && 24 }}><GroupAddOutlinedIcon fontSize='default' color='inherit' /></div><div style={{ fontSize: 12 }}>{matchesL ? `Megerősített (${ticketCounts.confirmed})` : null}</div></div>} value='confirmed' disabled={ticketCounts.confirmed === 0} />
              <Tab style={{ minWidth: 60 }} label={<div><div style={{ height: !matchesL && 24 }}><LocationOnOutlinedIcon fontSize='default' color='inherit' /></div><div style={{ fontSize: 12 }}>{matchesL ? `Folyamatban (${ticketCounts.in_progress})` : null}</div></div>} value='in_progress' disabled={ticketCounts.in_progress === 0} />
              <Tab style={{ minWidth: 60 }} label={<div><div style={{ height: !matchesL && 24 }}><HourglassEmptyOutlinedIcon fontSize='default' color='inherit' /></div><div style={{ fontSize: 12 }}>{matchesL ? `Várakozik (${ticketCounts.waiting})` : null}</div></div>} value='waiting' disabled={ticketCounts.waiting === 0} />
              <Tab style={{ minWidth: 60 }} label={<div><div style={{ height: !matchesL && 24 }}><NotInterestedOutlinedIcon fontSize='default' color='inherit' /></div><div style={{ fontSize: 12 }}>{matchesL ? `Visszautasított (${ticketCounts.rejected})` : null}</div></div>} value='rejected' disabled={ticketCounts.rejected === 0} />
              <Tab style={{ minWidth: 60 }} label={<div><div style={{ height: !matchesL && 24 }}><BeenhereOutlinedIcon fontSize='default' color='inherit' /></div><div style={{ fontSize: 12 }}>{matchesL ? `Jóváhagyandó (${ticketCounts.approve})` : null}</div></div>} value='approve' disabled={ticketCounts.approve === 0} />
            </Tabs>
          </Paper>
        }
        {tickets && tickets.length !== 0 && tickets.filter(item => statusMap[lastStatusSelection][item.ticketStatus]).sort((a, b) => (a.ticketId > b.ticketId) ? 1 : -1).map((item, index) => (
          <Grid key={index} item xs={12} md={6} lg={6} xl={4}>
            <TicketCard item={item} authedUser={authedUser} classes={classes} theme={theme}></TicketCard>
          </Grid>
        ))
        }
      </Grid>
    </Fragment>
  )
}