import React from 'react'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

export const DividerTitle = props => {
  return (
    <Grid item xs={11} sm={11} md={11} style={{ width:'100%'}}>
      <div style={{ borderBottom: `0.5px solid ${props.lineColor}` }}>
        <Typography variant='subtitle1' align='left' color='primary' style={{marginBottom: '0rem', marginTop: '2rem', fontWeight: 'normal'}}>{props.title}</Typography>
      </div>
    </Grid>
  )
}