import React, { useEffect, useState } from 'react'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Alert from '@material-ui/lab/Alert'


export const DropDown = props => {

  const { setClientMachines, state, setContacts, modification, selectOptions, currentFormItem, formItems, userCo, clients, formState, formStateOriginal, classes, setInputMap, label, stateVar } = props

  const [disabled, setDisabled] = useState(true)
  useEffect(() => {
    const toDisable = () => {
      if (userCo !== 'SSPT' && ['clientId', 'requestorClient'].includes(stateVar)) return true
      if (stateVar === 'clientId' && (!clients || Object.keys(clients).length === 0)) return true
      if (formState.clientId === 'SSPT' && stateVar === 'clientId') return true
      if (modification && ['clientId', 'ticketType'].includes(stateVar)) return true
      if (formItems.indexOf(currentFormItem) < formItems.indexOf(stateVar)) return true
      return false
    }
    const currentlyToDisable = toDisable()
    if (disabled !== currentlyToDisable) {
      setDisabled(currentlyToDisable)
    }
  }, [userCo, disabled, currentFormItem, formItems, stateVar, modification, formState.clientId, clients])

  return (
    <FormControl className={classes.formControl} required={true} fullWidth={true}>
      <InputLabel>{label}</InputLabel>
      <Select
        value={formState[stateVar]}
        disabled={disabled}
        onChange={event => {
          switch (stateVar) {
            case 'clientId':
              setInputMap({
                [stateVar]: event.target.value,
                ticketType: '',
                machineType: '',
                machine: '',
                moduleName: '',
                counterState: 0,
                requestorClient: '',
              })
              setClientMachines([])
              setContacts([])
              break
            case 'machineType':
              setInputMap({
                [stateVar]: event.target.value,
                machine: '',
                moduleName: '',
                counterState: 0
              })
              break
            case 'machine':
              setInputMap({
                [stateVar]: event.target.value,
                counterState: 0
              })
              break
            case 'requestorClient':
              setInputMap({
                [stateVar]: event.target.value
              })
              break
            default:
              setInputMap({ [stateVar]: event.target.value })
          }
        }}
      >
        {selectOptions.clientId && selectOptions.clientId.length > 0 && stateVar === 'clientId' &&
          selectOptions.clientId
            .filter(item => {
              if (state && state.clientId === 'SSPT') return item === 'SSPT'
              else return item !== 'SSPT'
            })
            .filter(item => item !== "TEST")
            .map(key => {
              return <MenuItem key={key} value={key}>{key === "SSPT" ? "Smart Solutions Papírtechnológia Kft." : clients[key]}</MenuItem>
            })
        }

        {selectOptions.ticketType && selectOptions.ticketType.length > 0 && stateVar === 'ticketType' &&
          selectOptions.ticketType.map(key => (
            <MenuItem key={key} value={key}>{key}</MenuItem>))
        }

        {selectOptions.machineType && selectOptions.machineType.length > 0 && stateVar === 'machineType' &&
          selectOptions.machineType.sort((a, b) => a > b ? 1 : -1).map(key => (
            <MenuItem key={key} value={key}>{key}</MenuItem>))
        }

        {selectOptions.machine && Object.keys(selectOptions.machine).length > 0 && stateVar === 'machine' &&
          Object.entries(selectOptions.machine).sort((a, b) => a[1].machine > b[1].machine ? 1 : -1).map(item => (
            <MenuItem key={item[0]} value={item[1].machine}>{item[1].machine !== item[1].serial ? `${item[1].machine} (gy.sz. ${item[1].serial})` : `${item[1].machine}`}</MenuItem>))
        }

        {selectOptions.moduleName && selectOptions.moduleName.length > 0 && stateVar === 'moduleName' &&
          selectOptions.moduleName.map(item => (
            <MenuItem key={item} value={item}>{item}</MenuItem>))
        }

        {selectOptions.requestorClient && selectOptions.requestorClient.length > 0 && stateVar === 'requestorClient' &&
          selectOptions.requestorClient.sort((a, b) => a > b ? 1 : -1).map(item => (
            <MenuItem key={item} value={item}>{item}</MenuItem>))
        }

      </Select>
      {formStateOriginal && formState[stateVar] !== formStateOriginal[stateVar] && <Alert severity="info">Változott</Alert>}
    </FormControl>
  )
}