import React from 'react'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { ticketTypeMap } from '../../data/ticketTypeMap'

export const TicketDetails = props => {

  const { ticketEvent } = props

  return (
    <Grid container direction='row' justify='center' alignItems='baseline' align='left' style={{ marginBottom: '1rem' }}>
      <Grid item xs={4} sm={3} md={3} lg={4} xl={3}>
        <Grid container direction='column' justify='center' alignItems='flex-start'>
          {['Ticket:', 'Sor/típus:', 'Sorszám:', 'Modul:', 'Bejelentő:'].map((item, index) => (
            <Grid item key={index}>
              <Typography variant='body2' color='textSecondary' style={{ fontWeight: 'bold' }}>{item}</Typography>
            </Grid>
          ))
          }
        </Grid>
      </Grid>
      <Grid item xs={8} sm={9} md={9} lg={8} xl={9}>
        <Grid container direction='column' justify='center' alignItems='flex-start'>
          {[ticketTypeMap[ticketEvent.ticketType], ticketEvent.machineType, `${ticketEvent.machine ? ticketEvent.machine : ''}${ticketEvent.serial ? ` ${ticketEvent.serial}` : ''}`, ticketEvent.moduleName, ticketEvent.requestorClient].map((item, index) => (
            <Grid item key={index}>
              <Typography variant='body2' color='textSecondary'>{item ? item : '-'}</Typography>
            </Grid>
          ))
          }
        </Grid>
      </Grid>
    </Grid>
  )
}