import React, { Fragment, useContext, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CachedIcon from '@material-ui/icons/Cached';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import TextField from '@material-ui/core/TextField'
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography'
import Fade from '@material-ui/core/Fade'

// Subcomponents
import { AddTicketContext } from './AddTicket'
import { TicketingContext } from '../TicketingContext'
import { DropDown } from './Subcomponents/DropDown'
import DropDownHelper from './helper/dropDownHelpers'


export const TicketForm = props => {

  const { authedUser, userCo, contacts, setContacts, clientMachines, setClientMachines, classes } = useContext(TicketingContext)
  const { state, clients, formItems, setFormItems, formItemLists, modifyWindowPassed, formSubmitted, setFormSubmitted, setSuccessPage,
    modification, setModification, formState, setFormState, formStateOriginal,
    setInputMap, handleTicket, setModifyWindowPassed } = useContext(AddTicketContext)

  const [currentFormItem, setCurrentFormItem] = useState(formItems[0])
  const [selectOptions, setSelectOptions] = useState('')

  useEffect(() => {
    // this updates selectOptions every time formState, formItems or clientMachines are changed
    setSelectOptions({
      clientId: DropDownHelper.getClients(clients, userCo),
      ticketType: DropDownHelper.determineTicketTypeOptions(formState.clientId, authedUser),
      machineType: (clientMachines.length > 0 && formState.clientId) ? DropDownHelper.getMachineTypes(clientMachines, formState.clientId) : [],
      machine: formState.machineType ? DropDownHelper.getMachines(clientMachines, formState.clientId, formState.machineType) : [],
      moduleName: formState.machine ? DropDownHelper.getModules(clientMachines, formState.clientId, formState.machineType, formState.machine) : [],
      requestorClient: contacts
    })
  }, [formItems, formState, clientMachines, authedUser, clients, contacts, userCo])

  useEffect(() => {
    // cycles through formItems and fills in blank items in order if they only have 1 option
    if (selectOptions) {
      for (let stateItem of formItems) {
        setCurrentFormItem(stateItem) // this is crucial here so that currentFormItem is reset when formItems are changed (via ticketType)
        if (!formState[stateItem]) {
          switch (stateItem) {
            case 'ticketType':
              if (selectOptions.ticketType.length === 1) setInputMap({ 'ticketType': selectOptions.ticketType[0] })
              break
            case 'machineType':
              if (selectOptions.machineType.length === 1) setInputMap({ 'machineType': selectOptions.machineType[0] })
              break
            case 'machine':
              if (Object.keys(selectOptions.machine).length === 1) setInputMap({ 'machine': Object.entries(selectOptions.machine)[0][1].machine })
              break
            case 'moduleName':
              if (selectOptions.moduleName.length === 1) setInputMap({ 'moduleName': selectOptions.moduleName[0] })
              break
            case 'requestorClient':
              if (selectOptions.requestorClient.length === 1) {
                setInputMap({
                  'requestorClient': selectOptions.requestorClient[0],
                })
              }
              break
            default:
              break
          }
          break
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptions])

  useEffect(() => {
    //  this runs when ticketType is changed - it updates formItems according to the option chosen
    setFormItems(formState.ticketType ? formItemLists[formState.ticketType] : formItemLists.Hibabejelentés)
  }, [formItemLists, formState.ticketType, setFormItems])

  const isFormInvalid = (formItems, formState) => {
    // not checking separately for required and non-required items; include an empty / "don't know" option for every optional dropdown
    // empty option is beneficial as user can revert to empty after mistakenly selecting an option
    const relevantState = Object.keys(formState).filter(key => formItems.includes(key)).reduce((obj, key) => {
      // filtering formState to state items present in selected ticketType
      obj[key] = formState[key]
      return obj
    }, {})
    if (isDescriptionInvalid(relevantState.description)) return true
    if (Object.values(relevantState).includes('')) return true
    if ((relevantState.counterState && Number.isNaN(relevantState.counterState)) || (relevantState.counterState && !Number.isInteger(relevantState.counterState)) || relevantState.counterState === 0) return true
    return false
  }

  const isDescriptionInvalid = (description) => {
    return description &&
      (description.length < 15 ||
        description.split(' ').length < 3 ||
        description.split(' ').every(item => item.length < 4))
  }

  return (
    <Fade in={true} timeout={{ enter: 500 }}>
      <Grid container justify='center' align='left'>
        <Grid item xs={12}>
          <Grid container spacing={2} justify='center' wrap="wrap">
            <Grid item xs={10} sm={5}>
              {selectOptions &&
                <DropDown state={state} selectOptions={selectOptions} setContacts={setContacts} currentFormItem={currentFormItem} formItems={formItems} userCo={userCo} setClientMachines={setClientMachines} clients={clients} formState={formState} classes={classes} setInputMap={setInputMap} formSubmitted={formSubmitted} modification={modification}
                  label='Ügyfél'
                  stateVar='clientId'
                />
              }
            </Grid>
            <Grid item xs={10} sm={5}>
              {selectOptions &&
                <DropDown selectOptions={selectOptions} setContacts={setContacts} currentFormItem={currentFormItem} formItems={formItems} userCo={userCo} clientMachines={clientMachines} formState={formState} formStateOriginal={formStateOriginal} classes={classes} setInputMap={setInputMap} formSubmitted={formSubmitted}
                  label='Ticket típusa'
                  stateVar='ticketType'
                />
              }
            </Grid>
            {formItems.includes('machineType') && selectOptions &&
              <Fragment>
                <Grid item xs={10} sm={5}>
                  <DropDown selectOptions={selectOptions} setContacts={setContacts} currentFormItem={currentFormItem} formItems={formItems} userCo={userCo} clientMachines={clientMachines} formState={formState} formStateOriginal={formStateOriginal} classes={classes} setInputMap={setInputMap} formSubmitted={formSubmitted}
                    label='Gépsor / típus'
                    stateVar='machineType'
                  />
                </Grid>
                <Grid item xs={10} sm={5}>
                  <DropDown selectOptions={selectOptions} setContacts={setContacts} currentFormItem={currentFormItem} formItems={formItems} userCo={userCo} clientMachines={clientMachines} formState={formState} formStateOriginal={formStateOriginal} classes={classes} setInputMap={setInputMap} formSubmitted={formSubmitted}
                    label='Gép'
                    stateVar='machine'
                  />
                </Grid>
              </Fragment>
            }
            {formItems.includes('moduleName') && selectOptions &&
              <Grid item xs={10} sm={5}>
                <DropDown selectOptions={selectOptions} setContacts={setContacts} currentFormItem={currentFormItem} formItems={formItems} userCo={userCo} clientMachines={clientMachines} formState={formState} formStateOriginal={formStateOriginal} classes={classes} setInputMap={setInputMap} formSubmitted={formSubmitted}
                  label='Modul'
                  stateVar='moduleName'
                />
              </Grid>
            }
            <Grid item xs={10} sm={5}>
              {selectOptions &&
                <DropDown selectOptions={selectOptions} setContacts={setContacts} currentFormItem={currentFormItem} formItems={formItems} userCo={userCo} clientMachines={clientMachines} formState={formState} formStateOriginal={formStateOriginal} classes={classes} setInputMap={setInputMap} formSubmitted={formSubmitted}
                  label='Bejelentő / Igénylő'
                  stateVar='requestorClient'
                />
              }
            </Grid>
            <Grid item xs={10}>
              <form className={classes.root} noValidate autoComplete="on">
                <Grid container direction='column'>
                  <TextField
                    multiline
                    rows={5}
                    rowsMax={10}
                    required={true}
                    disabled={currentFormItem !== 'description'}
                    fullWidth={true}
                    value={formState.description}
                    onChange={event => {
                      setInputMap({ 'description': event.target.value })
                    }}
                    label="Hiba vagy feladat leírása"
                    variant="filled"
                  />
                  {isDescriptionInvalid(formState.description) && <Alert severity="error">Kérjük írja le részletesen a felmerült hibát (min. 4-10 szóban)!</Alert>}
                  {formStateOriginal && formState.description !== formStateOriginal.description && <Alert severity="info">Változott</Alert>}
                </Grid>
              </form>
            </Grid>
            <Grid item xs={10} sm={10} md={5}>
              {(!modification || (modification && !modifyWindowPassed)) &&
                <Fragment>
                  <Typography variant='body2' align='center' style={{ marginTop: '1rem' }}>
                    {modification && formStateOriginal && JSON.stringify(formStateOriginal) === JSON.stringify(formState)
                      ? 'Nem került módosításra egy elem sem.'
                      : <br />
                    }
                  </Typography>
                  <Button
                    variant='contained'
                    color='primary'
                    fullWidth={true}
                    style={{ marginBottom: '1rem' }}
                    disabled={isFormInvalid(formItems, formState) || formSubmitted || (formStateOriginal && JSON.stringify(formStateOriginal) === JSON.stringify(formState))}
                    startIcon={modification ? <CachedIcon /> : <CloudUploadIcon />}
                    onClick={() => {
                      setFormSubmitted(true)
                      window.scroll(0, 0)
                      handleTicket(formStateOriginal, formState)
                    }}
                  >{modification ? 'Módosítások beküldése' : 'Ticket létrehozása'}
                  </Button>
                </Fragment>
              }
              {modification && modifyWindowPassed &&
                <Typography variant='body2' align='center'>Több mint 5 perc telt el a ticket rögzítése óta. A ticket módosítására a továbbiakban nincs lehetősége.</Typography>
              }
              {modification &&
                <Button
                  variant='outlined'
                  color='primary'
                  fullWidth={true}
                  style={{ marginBottom: '2rem' }}
                  endIcon={<ArrowForwardIcon />}
                  onClick={() => {
                    setFormState({ ...formStateOriginal })
                    setModifyWindowPassed(true)
                    window.scroll(0, 0)
                    setModification(false)
                    setSuccessPage(true)
                  }}
                >Vissza az összefoglalóhoz
                </Button>
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fade>
  )
}