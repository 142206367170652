import React, { Fragment } from 'react'

export const CornerIndicator = props => {

  const { size, theme } = props

  return (
    <Fragment>
      <div style={{
        position: 'absolute',
        top: `-${size / 15}rem`,
        right: `-${size / 15}rem`,
        borderBottomWidth: `${size}px`,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.primary.main,
        borderTop: `${size}px solid transparent`,
        borderRight: `${size}px solid transparent`,
        borderLeft: `${size}px solid transparent`,
        width: 0,
        height: 0,
        transform: 'rotate(45deg)'
      }}>
        <div style={{
          color: 'white',
          fontSize: `${size / 2}px`,
          fontWeight: 'bold',
          transform: 'rotate(-45deg)',
          position: 'relative',
          right: '0.3rem',
          top: '1rem'
        }}>!</div>
      </div>
    </Fragment>
  )
}