export const ssptUsers = [
  {
    displayName: "Dinh Xuan Tho",
    email: "dinh@sspt.hu",
    noPhoto: true,
  },
  {
    displayName: "Babtiszta Zoltán",
    email: "babtiszta.zoltan@sspt.hu",
    tech: true,
  },
  {
    displayName: "Szeles Gergő",
    email: "szeles.gergo@sspt.hu",
    tech: true,
    status: {
      assist: true,
    },
  },
  {
    displayName: "Vónya János",
    email: "vonya.janos@sspt.hu",
    tech: true,
  },
  {
    displayName: "Takács Modeszta",
    email: "takacs.modeszta@sspt.hu",
    noPhoto: true,
  },
  {
    displayName: "Stankovics Endre",
    email: "stankovics.endre@sspt.hu",
    tech: true,
    noPhoto: true,
    status: {
      assist: true,
    },
  },
  {
    displayName: "Olajos Éva",
    email: "olajos.eva@sspt.hu",
    noPhoto: true,
    tech: true,
    status: {
      assist: true,
    },
  },
  {
    displayName: "Dinh Tamás",
    email: "dinh.tamas@sspt.hu",
    tech: false,
  },
  {
    displayName: "SSPT Ticketing",
    email: "ticketingbot@sspt.hu",
    noPhoto: true,
  },
  {
    displayName: "Brett Nándor",
    email: "brett.nandor@sspt.hu",
    tech: false,
    status: {
      text: "részmunkaidős",
    },
  },
  {
    displayName: "Szabó Gyula",
    email: "szabo.gyula@sspt.hu",
    tech: true,
  },
  {
    displayName: "Erdős Zoltán",
    email: "erdos.zoltan@sspt.hu",
    tech: true,
  },
  {
    displayName: "Ambrus Zoltán",
    email: "ambrus.zoltan@sspt.hu",
    tech: true,
  },
  {
    displayName: "Peszteritz Ferenc",
    email: "peszteritz.ferenc@sspt.hu",
    tech: true,
  },
  {
    displayName: "Meszes Attila",
    email: "meszes.attila@sspt.hu",
    tech: false,
  },
  {
    displayName: "Molnár Ferenc",
    email: "molnar.ferenc@sspt.hu",
    tech: true,
  },
  {
    displayName: "Sitó Tamás",
    email: "sito.tamas@sspt.hu",
    tech: true,
  },
];
