import React, { useEffect, useState, Fragment, useContext, createContext } from 'react'
import Paper from '@material-ui/core/Paper'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Fab from '@material-ui/core/Fab'
import CheckIcon from '@material-ui/icons/Check'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

// Subcomponents
import { TicketingContext } from '../TicketingContext'
import { TicketForm } from './TicketForm'
import { UploadProgress } from './Subcomponents/UploadProgress'
import { TicketSuccess } from './TicketSuccess'
import { usePreviousValue } from '../components/helper/usePreviousValue'

// Backend API call
import { createTicket, modifyTicket } from '../requests/ticketRequest'
import { errorHandlingWrapper } from './helper/errorHandlingWrapper'
import { ticketTypeMap } from '../data/ticketTypeMap'

const formItemLists = {
  Hibabejelentés: ['clientId', 'ticketType', 'machineType', 'machine', 'moduleName', 'requestorClient', 'description'],
  'Műszaki felügyelet': ['clientId', 'ticketType', 'requestorClient', 'description'],
  TMK: ['clientId', 'ticketType', 'machineType', 'machine', 'requestorClient', 'description'],
  Installáció: ['clientId', 'ticketType', 'requestorClient', 'description'],
  Átépítés: ['clientId', 'ticketType', 'machineType', 'machine', 'requestorClient', 'description'],
  Felújítás: ['clientId', 'ticketType', 'requestorClient', 'description'],
  Szállítás: ['clientId', 'ticketType', 'requestorClient', 'description'],
  Gyártás: ['clientId', 'ticketType', 'requestorClient', 'description'],
  Egyéb: ['clientId', 'ticketType', 'requestorClient', 'description']
}

export const AddTicketContext = createContext()

export const AddTicket = props => {

  const { errorType, setErrorType, setLoadingSuccess, clients, listClients, getContacts, clientMachines, getClientMachines, tickets, setTickets, classes, theme, authedUser, userCo } = useContext(TicketingContext)

  const initialFormState = {
    ticketType: props.location.state?.clientId === 'SSPT' ? 'Felújítás' : '',
    clientId: userCo !== 'SSPT' ? userCo : props.location.state?.clientId ? props.location.state.clientId : '',
    machineType: '',
    machine: '',
    moduleName: '',
    requestorClient: userCo !== 'SSPT' ? authedUser.attributes.name : '',
    description: '',
    submittedBy: userCo !== 'SSPT' ? authedUser.attributes.name : authedUser.attributes.email.split('@')[0]
  }

  const [formState, setFormState] = useState(initialFormState)
  const [ticketId, setTicketId] = useState('')
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [success, setSuccess] = useState(false);
  const [successPage, setSuccessPage] = useState(false);
  const [modification, setModification] = useState(false);
  const [formStateOriginal, setFormStateOriginal] = useState()
  const [formItems, setFormItems] = useState(formState.ticketType ? formItemLists[formState.ticketType] : formItemLists.Hibabejelentés)
  const [fetchActive, setFetchActive] = useState(false)
  const [modifyWindowPassed, setModifyWindowPassed] = useState(false)
  const [tabSelection, setTabSelection] = useState((props.location.state && props.location.state.clientId) ? props.location.state.clientId.split('/').slice(-1)[0] : 'CLIENT')

  const previousClientSelected = usePreviousValue(formState.clientId)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    if(!formState.clientId) {
      listClients()
    }
  }, [formState.clientId, listClients])

  useEffect(() => {
    let timerID
    if (successPage && !modifyWindowPassed) {
      console.log('setting countdown timer for initiating modification')
      timerID = setTimeout(() => {
        setModifyWindowPassed(true)
      }, 1 * 60 * 1000)
    }
    return () => {
      if (timerID) {
        clearTimeout(timerID)
        console.log('clearing timer for initiating modification')
      }
    }
  }, [modifyWindowPassed, successPage])

  useEffect(() => {
    let timerID
    if (modification && !modifyWindowPassed) {
      console.log('setting countdown timer for modification')
      timerID = setTimeout(() => {
        setModifyWindowPassed(true)
      }, 2 * 60 * 1000)
    }
    return () => {
      if (timerID) {
        clearTimeout(timerID)
        console.log('clearing timer for modification')
      }
    }
  }, [modification, modifyWindowPassed, successPage])

  useEffect(() => {
    try {
      if (formState.clientId === "SSPT" && previousClientSelected !== "SSPT") {
        getContacts(formState.clientId)
      }
    } catch (error) {
      setErrorType(error.message)
    }
  }, [formState.clientId, getContacts, previousClientSelected, setErrorType])

  useEffect(() => {
    if (errorType) {
      setLoadingSuccess(true)
      setUploading(false)
    }
  }, [errorType, setLoadingSuccess])

  useEffect(() => {
    try {
      if (formState.clientId && formState.clientId !== "SSPT" && previousClientSelected !== formState.clientId && !fetchActive) {
        setFetchActive(true)
        getClientMachines(formState.clientId, userCo, authedUser)
      }
    } catch (error) {
      setErrorType(error.message)
    }
  }, [authedUser, clientMachines, fetchActive, formState.clientId, getClientMachines, previousClientSelected, setErrorType, userCo])

  useEffect(() => {
    if (clientMachines.length > 0 && fetchActive) setFetchActive(false)
  }, [clientMachines, fetchActive])

  const setInputMap = (keyValueMap) => {
    setFormState({ ...formState, ...keyValueMap })
  }

  const handleTicket = async (originalFormData, formData) => {
    try {
      setUploading(true)
      let response
      const relevantState = Object.keys(formData).filter(key => formItems.includes(key)).reduce((obj, key) => {
        // filtering formState to state items present in selected ticketType
        obj[key] = formData[key]
        return obj
      }, {})
      if (relevantState.machineType && relevantState.machine) {
        const potentialMachines = clientMachines.filter(item =>
          item.SearchID === `${relevantState.clientId}#${relevantState.machineType}#${relevantState.machine}${relevantState.moduleName ? (relevantState.moduleName === "-" ? "" : `#${relevantState.moduleName.replace(" ", "-")}`) : ""}`
        )
        if (potentialMachines.length !== 1) {
          throw new Error("Could not identify selected machine based on form content. This is a data model problem; please contact SSPT.")
        } else {
          relevantState.machineID = potentialMachines[0].sortKey
          relevantState.machineSearchID = potentialMachines[0].SearchID
          relevantState.serial = potentialMachines[0].serial
        }
      }
      relevantState.ticketType = Object.entries(ticketTypeMap).filter(item => item[1] === relevantState.ticketType)[0][0]
      if (!modification) {
        response = await errorHandlingWrapper(createTicket, relevantState)
      } else {
        const relevantOriginalState = Object.keys(originalFormData).filter(key => formItems.includes(key)).reduce((obj, key) => {
          // filtering formState to state items present in selected ticketType
          obj[key] = originalFormData[key]
          return obj
        }, {})
        relevantOriginalState.ticketType = Object.entries(ticketTypeMap).filter(item => item[1] === relevantOriginalState.ticketType)[0][0]
        response = await errorHandlingWrapper(modifyTicket, relevantOriginalState, relevantState, ticketId)
      }
      setTicketId(response.created.ticketId)
      if (tickets instanceof Array) {
        if (modification && response.deleted) {
          setTickets([...tickets.filter(item => item.sortKey !== response.deleted.sortKey), response.created])
        } else {
          setTickets([...tickets.filter(item => item.ticketId !== response.created.ticketId), response.created])
        }
      }
      setSuccess(true)
      setUploading(false)
      setTimeout(() => {
        setFormSubmitted(false)
        setSuccess(false)
        setSuccessPage(true)
      }, 1500)
    } catch (error) {
      console.log(error)
      setErrorType(error.message)
    }
  }

  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  const handleTabChange = (_, newValue) => {
    setTabSelection(newValue)
    if (newValue === 'CLIENT') {
      props.history.push('/')
    } else {
      props.history.push(`/${newValue}`)
    }
  }

  return (
    <Fragment>
      {userCo === 'SSPT' &&
        <Paper square elevation={4} style={{ width: '100%', marginBottom: '2rem', position: 'fixed', top: 45, zIndex: 100, paddingTop: 10 }}>
          <Tabs
            style={{ height: 50 }}
            value={tabSelection}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
            variant={matchesXS ? 'fullWidth' : 'standard'}
            centered
          >
            <Tab label={'Ügyfél ticket'} value='CLIENT' />
            <Tab label={'SSPT ticket'} value='SSPT' />
          </Tabs>
        </Paper>
      }
      { (clients || clientMachines) &&
        <Fade in={true} timeout={{ enter: 500 }}>
          <Fragment>
            {userCo === 'SSPT' &&
              <Box height={50} />
            }
            <Paper elevation={6} square style={{ marginTop: '1rem', padding: '1rem', overflow: 'hidden', maxWidth: 900 }}>
              <Grid container direction='column' justify='center' alignItems='center'>
                <Grid item xs={8} sm={5} md={4} lg={3} style={{ textAlign: 'center', maxHeight: '5rem', marginBottom: '1.5rem' }}>
                  <img src='/sspt_logo1.jpg' width='100%' height='auto' alt='Smart Solutions Papírtechnológia Kft. logo'></img>
                </Grid>
                <Grid container direction='row' justify='center' alignItems='center'
                  style={{ marginTop: `${matchesSM && !successPage ? '2rem' : '0.5rem'}`, marginBottom: `${modification && !successPage ? '0rem' : '2rem'}` }}>
                  <Grid item style={{ marginRight: '0.5rem' }}>
                    {successPage && JSON.stringify(formState) === JSON.stringify(formStateOriginal) &&
                      <InfoOutlinedIcon variant='outlined' fontSize='large' style={{ color: `${theme.palette.primary.light}` }} />
                    }
                    {successPage && JSON.stringify(formState) !== JSON.stringify(formStateOriginal) &&
                      <Fab size={matchesXS ? 'small' : 'medium'} style={{ backgroundColor: `${theme.palette.success.main}` }}>
                        <CheckIcon style={{ color: 'white' }} />
                      </Fab>
                    }
                  </Grid>
                  <Grid item>
                    <Typography variant='h5' align='center'>
                      {(successPage && JSON.stringify(formState) === JSON.stringify(formStateOriginal) && 'Nem történt módosítás')
                        || (successPage && !modification && 'A ticket rögzítésre került.')
                        || (!successPage && modification && 'Ticket módosítása')
                        || (successPage && modification && 'A ticket módosításra került.')
                        || 'Szervízigény bejelentése'}
                    </Typography>
                    {modification && !successPage &&
                      <Typography variant='h5' align='center' color='primary' style={{ marginBottom: '2rem', fontStyle: 'italic' }}>{ticketId}</Typography>
                    }
                  </Grid>
                </Grid>
                <AddTicketContext.Provider value={{
                  state: props.location.state,
                  clients,
                  ticketId,
                  success,
                  formItemLists,
                  formItems,
                  setFormItems,
                  formSubmitted,
                  setFormSubmitted,
                  successPage,
                  setSuccessPage,
                  modification,
                  setModification,
                  formState,
                  setFormState,
                  formStateOriginal,
                  setFormStateOriginal,
                  setInputMap,
                  modifyWindowPassed,
                  errorType,
                  setErrorType,
                  handleTicket,
                  setModifyWindowPassed
                }}>
                  {!successPage && formItems &&
                    <TicketForm />
                  }
                  {formSubmitted && !successPage &&
                    <UploadProgress upLoading={uploading} errorType={errorType} setErrorType={setErrorType} setFormSubmitted={setFormSubmitted} classes={classes} theme={theme} />
                  }
                  {successPage && formItems &&
                    <TicketSuccess />
                  }
                </AddTicketContext.Provider>
              </Grid>
            </Paper>
          </Fragment>
        </Fade>
      }
    </Fragment>
  )
}