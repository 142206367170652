export const ticketStatusMap = {
  "OPEN": "NYITOTT",
  "PLANNED": "TERVEZETT",
  "REOPENED": "ÚJRANYITOTT",
  "CONFIRMED": "MEGERŐSÍTETT",
  "IN_PROGRESS": "FOLYAMATBAN",
  "WAITING_FOR_PARTS": "ALKATRÉSZRE VÁR",
  "TO_BE_FINALIZED": "VÉGLEGESÍTÉSRE VÁR",
  "WAITING_FOR_OEM_SUPPORT": "GYÁRTÓI SUPPORTRA VÁR",
  "REJECTED_CLOSE": "ZÁRÁS ELUTASÍTVA",
  "REJECTED_CANCEL": "LEMONDÁS ELUTASÍTVA",
  "PENDING_CANCEL": "LEMONDÁS KÉRELMEZVE",
  "PENDING_CLOSE": "ZÁRÁS KÉRELMEZVE",
  "CLOSED": "LEZÁRVA",
  "CANCELLED": "LEMONDVA"
}