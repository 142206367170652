export const getHolidayStartTime = ({ holiday, queryDay }) => {
  const queryDaySplit = queryDay.split("-")
  queryDaySplit[1] -= 1
  return (holiday?.halfDay && !holiday?.morning) ? new Date(...queryDaySplit, 12).toISOString() : new Date(...queryDaySplit, 7).toISOString()
}

export const getHolidayEndTime = ({ holiday, queryDay }) => {
  const queryDaySplit = queryDay.split("-")
  queryDaySplit[1] -= 1
  return (holiday?.halfDay && holiday?.morning) ? new Date(...queryDaySplit, 12).toISOString() : new Date(...queryDaySplit, 17).toISOString()
}