import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'

import { TicketingContext } from '../TicketingContext'
import { ErrorPage } from './ErrorPage'
import { setErrorType } from './helper/setErrorType'

export const ProtectedRoute = ({ component: Component, ...props }) => {

  const { authedUser, ticketDetails } = useContext(TicketingContext)
  const { ticketId } = props.computedMatch.params

  const errorType = setErrorType(props, authedUser, ticketDetails, ticketId)
  return (
    <Route {...props} render={(routerProps) => {
      if (!errorType) {
        return <Component {...routerProps} {...props} />
      } else if (errorType === 'noOptions' && props.computedMatch.params.ticketId) {
        return <Redirect to={`/ticketek/${props.computedMatch.params.ticketId}`} />
      } else {
        return <ErrorPage errorType={errorType} authedUser={authedUser} />
      }
    }} />
  )
}