import React, { Fragment } from 'react'
import Typography from '@material-ui/core/Typography'


export const Title = props => {
  return (
    <Fragment>
      <Typography variant='h5' align='center' color='primary' style={{marginBottom: '2rem', marginTop: '2rem', fontStyle: 'bold'}}>{props.title}</Typography>
    </Fragment>
  )
}