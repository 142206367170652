import { submitTicketEvent } from '../../requests/ticketRequest'
import { errorHandlingWrapper } from './errorHandlingWrapper'

export async function handleEventSubmission({ ticketDetails, setTicketDetails, ticketId, tickets, setTickets,
  payLoad, authedUser, setUpLoading, setLoadingSuccess, history }) {
  try {
    const response = await errorHandlingWrapper(submitTicketEvent, payLoad)
    if (!response.data.errors) {

      const { updateItem, statusUpdateObject, techs, arrivals, options } = response.data.ticketEvents
      setUpLoading(false)

      const statusUpdateClean = {}
      Object.entries(statusUpdateObject).forEach(item => {
        if (item[1] != null) statusUpdateClean[item[0]] = item[1]
      })

      setTimeout(() => {
        setLoadingSuccess(true)
        if (tickets instanceof Array && (updateItem.ticketStatus || updateItem.clientApproval || updateItem.ssptApproval)) {
          let currentTicketUpdated = { ...tickets.find(item => item.ticketId === updateItem.ticketId), ...statusUpdateClean }
          setTickets([...tickets.filter(item => item.ticketId !== updateItem.ticketId), currentTicketUpdated]
            .filter(item => {
              if (authedUser.attributes['custom:company'] !== 'SSPT') {
                return !item.clientApproval
              } else {
                return (!item.ssptApproval || !item.clientApproval)
              }
            }))
        }
  
        setTicketDetails({
          ...ticketDetails,
          [ticketId]: {
            ...ticketDetails[ticketId],
            ticketData: {...ticketDetails[ticketId].ticketData, ...statusUpdateClean},
            ticketEvents: [
              ...ticketDetails[ticketId].ticketEvents,
              updateItem
            ],
            techs,
            arrivals,
            options
          }
        })
        history.push(`/ticketek/${ticketId}`)
      }, 1500)


    }
    
  } catch (error) {
    throw (error)
  }
}