export const setErrorType = (props, authedUser, ticketDetails, ticketId) => {
  const location = props.location.pathname.split('/').slice(-1)[0]
  let errorType
  if (location === 'newticket' && authedUser.attributes['custom:company'] === 'SSPT' && parseInt(authedUser.attributes['custom:admin']) !== 1) {
    errorType = 'notadmin'
  } else if (location === 'modadmin' && authedUser.attributes['custom:company'] === 'SSPT' && parseInt(authedUser.attributes['custom:admin']) !== 1) {
    errorType = 'notadmin'
  } else if (ticketId && !ticketDetails[ticketId]?.options) {
    errorType = 'noOptions'
  } else if (location !== 'newticket' && !['events'].includes(location) && !ticketDetails[ticketId]?.options?.events.includes(location.toUpperCase())) {
    errorType = 'notallowed'
  }
  return errorType
}