import React, { useState } from 'react'

import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { TicketingIconChooser } from './TicketingIconChooser'

const determineTitle = (option, hasAdminRights) => {
  if (option.event === 'CONFIRM' && !hasAdminRights) return 'Megerősítés saját munkaként'
  return option.title
}

const determineDescription = (option, hasAdminRights) => {
  if (option.event === 'CONFIRM' && !hasAdminRights) return 'Ügyelet vagy műszaki felügyelet során kapott bejelentés esetén megerősítheted és magadhoz rendelheted a ticketet.'
  return option.description
}

export const DashboardCard = props => {

  const { classes, option, history, options } = props

  const [cardRaised, setCardRaised] = useState(true)
  const [cardTitle] = useState(determineTitle(option, options.hasAdminRights))

  return (
    <Card className={classes.root} style={{ minWidth: '300px', maxWidth: '500px', minHeight: '140px', textAlign: 'left' }}
      raised={cardRaised}
      onClick={() => {
        setCardRaised(false)
        history.push(`${history.location.pathname}/${option.event.toLowerCase()}`)
      }}>
      <CardContent style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Grid container direction='row' alignItems='center' spacing={2}>
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <TicketingIconChooser event={option.event} size='large' />
          </Grid>
          <Grid container direction='column' item align='left' xs={10}>
            <Grid item>
              <Typography variant='body2' className={classes.cardLabel} color="textSecondary">{option.label}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" className={classes.cardTitle} gutterBottom color='primary'>{cardTitle}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" color='textSecondary'>{determineDescription(option, options.hasAdminRights)}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}