import React, { Fragment, useState } from 'react'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import { AvatarComp } from './AvatarComp'
import { ssptUsers } from '../../data/ssptUsers'

const getDisplayNames = (techList) => {
  const techs = techList.map(tech =>
    ssptUsers.find(ssptUser => ssptUser.email.split('@')[0] === tech)
  )
  return techs.map(tech => ({
    displayName: tech.displayName,
    noPhoto: tech.noPhoto ? true : false
  }))
}

export const TechList = props => {

  const { classes, theme, techsToBeAdded } = props

  const [displayNames] = useState(getDisplayNames(techsToBeAdded))

  return (
    <Fragment>
      { techsToBeAdded.map((tech, index) => (
        <Grid key={index} container direction='row' justify='flex-start' alignItems='center' spacing={2} style={{ marginTop: '0.5rem', marginBottom: '0.5rem', marginLeft: '1rem' }}>
          <AvatarComp classes={classes} theme={theme} displayName={displayNames[index].displayName} userId={displayNames[index].noPhoto ? null : tech} />
          <Typography variant='body2' color='textSecondary' style={{ marginLeft: '0.5rem' }}>{displayNames[index].displayName}</Typography>
        </Grid>
      ))
      }
    </Fragment>
  )
}