import React, { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'

// Amplify libraries
import Amplify, { I18n } from 'aws-amplify'
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react'
import { AuthState, onAuthUIStateChange, Translations } from '@aws-amplify/ui-components'

// UI elements
import { makeStyles, responsiveFontSizes, createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import grey from '@material-ui/core/colors/grey'
import red from '@material-ui/core/colors/red'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import './App.css'

// Subcomponents
import { Internal } from './Internal'
// import { ErrorPage } from './components/ErrorPage'

Amplify.configure({
  Auth: {
    region: 'europe-central-1',
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_FRONTEND_APP_ID,
    mandatorySignIn: true,
    cookieStorage: {
      domain: process.env.REACT_APP_DOMAIN,
      path: '/',
      expires: 7,
      sameSite: "none",
      secure: true
    },
  },
  API: {
    graphql_headers: async () => {
      const session = await Auth.currentSession();
      return {
        Authorization: session.getIdToken().getJwtToken(),
      };
    },
  },
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_ENDPOINT,
  aws_appsync_region: 'eu-central-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',

})

I18n.putVocabulariesForLanguage('hu', {
  [Translations.SIGN_IN_HEADER_TEXT]: 'Ticketing rendszer belépés',
  [Translations.SIGN_IN_ACTION]: 'Belépés',
  [Translations.EMAIL_LABEL]: 'Emailcím *',
  [Translations.EMAIL_PLACEHOLDER]: 'Kérjük ide írja be a regisztrált emailcímét',
  [Translations.PASSWORD_LABEL]: 'Jelszó *',
  [Translations.PASSWORD_PLACEHOLDER]: 'Kérjük ide írja be a jelszavát',
  [Translations.BACK_TO_SIGN_IN]: 'Vissza a Belépés oldalra',
  [Translations.FORGOT_PASSWORD_TEXT]: 'Elfelejtette a jelszavát?',
  [Translations.SEND_CODE]: 'Kód küldése',
  [Translations.RESET_PASSWORD_TEXT]: 'Itt tud újat kérni.',
  [Translations.RESET_YOUR_PASSWORD]: 'Új jelszó igénylése',
  [Translations.SUBMIT]: 'Beküldés',
  [Translations.NEW_PASSWORD_LABEL]: 'Új jelszó',
  [Translations.NEW_PASSWORD_PLACEHOLDER]: 'Ide írja be az új jelszavát',
  [Translations.CODE_LABEL]: 'Megerősítő kód',
  [Translations.CODE_PLACEHOLDER]: 'Ide írja be a kapott megerősítő kódot',
  [Translations.SIGN_OUT]: 'Kilépés',
  [Translations.CHANGE_PASSWORD]: 'Jelszó módosítása',
  [Translations.CHANGE_PASSWORD_ACTION]: 'Jelszó módosítása'
})

I18n.setLanguage('hu')

let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0046B5'
    },
    secondary: {
      main: grey[700]
    },
    success: {
      main: '#4caf50'
    },
    error: {
      main: red[600]
    }
  },
  overrides: {
    MuiTab: {
      textColorPrimary: { color: grey[900] }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 800,
      lg: 1000,
      xl: 1200,
      xxl: 1400
    },
  },
});
theme = responsiveFontSizes(theme)

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    '-o-user-select': 'none',
    userSelect: 'none'
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  cardTitle: {
    lineHeight: '1.2rem',
    fontSize: '1.2rem',
    marginBottom: '1rem'
  },
  cardLabel: {
    lineHeight: '1.2rem',
    fontSize: '0.8rem'
  },
  indicator: {
    backgroundColor: 'white',
    height: 2
  },
  ticketText: {
    color: grey[600]
  },
  ticketEventCard: {
    minWidth: '200px',
    minHeight: '125px',
    marginRight: '1rem',
    marginBottom: '2rem'
  },
  ticketEventCardTitle: {
    [theme.breakpoints.down("xs")]: {
      marginTop: '0.5rem',
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: '1rem',
    },
  },
  formControl: {
    margin: 0
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  progressModal: {
    position: 'fixed',
    margin: 'auto',
    top: '35%',
    width: '50%',
    minWidth: 350
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'rgba(200, 200, 200, 0.4)',
  },
  menuButton: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2)
    }
  },
  drawerText: {
    color: grey[600]
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar
}));

export const App = () => {

  const classes = useStyles()

  const [authState, setAuthState] = useState()
  const [authedUser, setAuthedUser] = useState()

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState)
      setAuthedUser(authData)
    })
  })

  // const returnTime = '2021. április 19. 6:00-kor'
  // return (
  //   <ErrorPage errorType={'maintenance'} returnTime={returnTime} classes={classes} theme={theme}/>
  // )

  return authState === AuthState.SignedIn && authedUser
    ? (
      <ThemeProvider theme={theme}>
        <Box align='center' style={{
          width: '100%',
          marginLeft: 0,
          marginTop: 0
        }}>
          <Internal authedUser={authedUser} classes={classes} theme={theme} />
        </Box>
      </ThemeProvider>
    )
    : (
      <Grid container justify='flex-start' alignItems='center' direction='column'>
        <Grid item xs={6} sm={4} md={3} lg={2} xl={2} style={{ textAlign: 'center', marginTop: '1rem', marginBottom: '1.5rem' }}>
          <img src='/sspt_logo1.jpg' width='100%' height='auto' alt='Smart Solutions Papírtechnológia Kft. logo'></img>
        </Grid>
        <AmplifyAuthenticator style={{ '--container-align': 'flex-start' }}>
          <AmplifySignIn
            hideSignUp={true}
            slot="sign-in"
            usernameAlias="email"
          />
        </AmplifyAuthenticator>
      </Grid>
    )

}