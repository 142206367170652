import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Fade from '@material-ui/core/Fade'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Button from '@material-ui/core/Button'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'

import TextField from '@material-ui/core/TextField'

import { ssptUsers } from '../data/ssptUsers'

import { UploadProgress } from './Subcomponents/UploadProgress'
import { generatePayload } from './helper/generatePayload'
import { EventPageHeader } from './Subcomponents/EventPageHeader'
import { usePreviousValue } from '../components/helper/usePreviousValue'

import { TicketingContext } from '../TicketingContext'
import { getHolidayStartTime, getHolidayEndTime } from './helper/getHolidayTimes'

const techs = ssptUsers
  .filter(item => item.tech)
  .sort((a, b) => a.displayName.toUpperCase() > b.displayName.toUpperCase() ? 1 : -1)

const generateConfirmLabel = ({ queryDay, tech, arrivals, holidays, shouldDisableDueToHoliday, nightWatches }) => {
  let label = tech.displayName
  if (arrivals?.includes(tech.email.split('@')[0])) {
    label += ' (megérk.)'
  }
  if (shouldDisableDueToHoliday) {
    if (holidays[queryDay]?.find(holiday => holiday.tech === tech.email.split('@')[0])?.sickness === true) {
      label += ' (beteg)'
    } else {
      label += ' (szabi)'
    }
  }
  if (nightWatches[queryDay]?.tech === tech.email.split('@')[0]) {
    label += ' (ügy.)'
  }

  return label
}

const setDateTime = () => {
  const now = new Date()
  const options = {
    timeZone: 'Europe/Budapest',
    hour12: false,
    hour: '2-digit',
    minute: '2-digit'
  }
  let monthLocal = now.getMonth() + 1
  if (monthLocal.toString().length === 1) monthLocal = '0' + monthLocal.toString()
  let dateLocal = now.getDate()
  if (dateLocal.toString().length === 1) dateLocal = '0' + dateLocal.toString()
  return `${now.getFullYear()}-${monthLocal}-${dateLocal}T${now.toLocaleTimeString('hu-HU', options)}`
}

const determineInitialTeamState = (singleOption, authedUser, ticketDetails, ticketId) => {
  if (singleOption) return { [authedUser.attributes.email.split('@')[0]]: false }
  else {
    let techsMap = {}
    techs
      .map(techItem => techItem.email.split("@")[0])
      .map(techId => ticketDetails[ticketId].techs.includes(techId) ? techsMap[techId] = true : techsMap[techId] = false)
    return techsMap
  }
}

export const Jelzes = props => {

  const { submitEvent, getNightWatchAndHolidays, holidays, nightWatches, errorType, setErrorType, classes, theme, authedUser, ssptAdmin, setLoadingSuccess, ticketDetails } = useContext(TicketingContext)
  const { ticketId } = useParams()
  const history = useHistory()

  const [singleOption] = useState(!ssptAdmin)
  const [initialTeamState, setInitialTeamState] = useState({})
  const [teamSelected, setTeamSelected] = useState({})
  const [arrivals] = useState(ticketDetails[ticketId].arrivals)
  const [teamChanged, setTeamChanged] = useState(false)
  const [techAdded, setTechAdded] = useState(false)
  const [etaLocal, setEtaLocal] = useState(setDateTime())
  const [queryDay, setQueryDay] = useState(etaLocal.split("T")[0])
  const [shiftDuration, setShiftDuration] = useState(1)
  const [isLoading, setIsLoading] = useState(true)
  const [upLoading, setUpLoading] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)

  const previousQueryDay = usePreviousValue(queryDay)

  useEffect(() => {
    window.scroll(0, 0)
    const initialTeamState = determineInitialTeamState(singleOption, authedUser, ticketDetails, ticketId)
    setInitialTeamState(initialTeamState)
    setTeamSelected(!singleOption ? initialTeamState : { [authedUser.attributes.email.split('@')[0]]: true })
  }, [authedUser, singleOption, ticketDetails, ticketId])
  
  useEffect(() => {
    if (queryDay !== previousQueryDay) {
      getNightWatchAndHolidays({ queryDay: etaLocal.split("T")[0] })
    }
  }, [etaLocal, getNightWatchAndHolidays, previousQueryDay, queryDay])

  useEffect(() => {
    if (errorType) {
      setLoadingSuccess(true)
    }
  }, [errorType, setLoadingSuccess])

  useEffect(() => {
    if (Object.keys(initialTeamState).length > 0) setIsLoading(false)
  }, [initialTeamState])

  const errorLocal = !Object.values(teamSelected).includes(true)

  const handleTeamChange = (event) => {
    setTeamSelected({ ...teamSelected, [event.target.name]: event.target.checked })
  }

  useEffect(() => {
    let changed = false
    let techAdded = false
    for (let tech of Object.keys(initialTeamState)) {
      if (initialTeamState[tech] !== teamSelected[tech]) {
        changed = true
        if (teamSelected[tech] && !initialTeamState[tech]) {
          techAdded = true
          break
        }
      }
    }
    changed && setTeamChanged(true)
    !changed && setTeamChanged(false)
    techAdded && setTechAdded(true)
    !techAdded && setTechAdded(false)
  }, [initialTeamState, teamSelected])

  const handleSubmitEvent = async () => {
    setFormSubmitted(true)
    setUpLoading(true)
    try {
      const payLoad = generatePayload({ eventType: props.location.pathname.split('/').slice(-1)[0].toUpperCase(), ticketId })

      const initialTeamSet = new Set(Object.entries(initialTeamState).filter(item => item[1] === true).map(item => item[0]))
      const newTeamSet = new Set(Object.entries(teamSelected).filter(item => item[1] === true).map(item => item[0]))
      const techsToBeRemoved = new Set([...initialTeamSet].filter(tech => !newTeamSet.has(tech)))
      const techsToBeAdded = new Set([...newTeamSet].filter(tech => !initialTeamSet.has(tech)))
      payLoad.techsToBeAdded = [...techsToBeAdded]
      payLoad.techsToBeRemoved = [...techsToBeRemoved]
      if (payLoad.techsToBeAdded.length > 0) {
        payLoad.eta = new Date(etaLocal).toISOString()
        payLoad.shiftDuration = shiftDuration
      }

      await submitEvent({ payLoad, ticketId, setUpLoading, history: props.history })

    } catch (error) {
      console.log('An error has occured:\n', error)
      setErrorType(error.message || "GraphQLValidationError")
      setUpLoading(false)
    }
  }

  const handleSubmitSingle = async () => {
    setFormSubmitted(true)
    setUpLoading(true)
    try {
      const payLoad = generatePayload({ eventType: props.location.pathname.split('/').slice(-1)[0].toUpperCase(), ticketId })
      payLoad.techsToBeAdded = Object.keys(teamSelected)
      payLoad.eta = new Date(etaLocal).toISOString()
      payLoad.shiftDuration = shiftDuration

      await submitEvent({ payLoad, ticketId, setUpLoading, history: props.history })

    } catch (error) {
      console.log('An error has occured:\n', error)
      setErrorType(error.message)
      setUpLoading(false)
    }
  }

  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'))
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Container style={{ padding: 0, margin: 'auto' }}>
      <EventPageHeader ticketId={ticketId} options={ticketDetails[ticketId].options} />
      <Fade in={true} timeout={{ enter: 500 }}>
        <Paper elevation={8} square style={{ margin: 0, padding: '1rem', overflow: 'hidden' }}>
          {formSubmitted &&
            <UploadProgress upLoading={upLoading} errorType={errorType} setErrorType={setErrorType} setFormSubmitted={setFormSubmitted} classes={classes} theme={theme} />
          }
          <Grid container justify='center' alignItems='center' direction='column'>
            <Typography variant='h5' align='center' color='primary' style={{ marginBottom: '2rem', marginTop: '2rem', fontStyle: 'bold' }}>
              {history.location.pathname.split("/").slice(-1)[0] === 'confirm' ? 'Ticket megerősítése és kiosztása' : 'Ticket csapat módosítása'}
            </Typography>
            <Grid container direction='row' justify='center' spacing={4} style={{ marginTop: '1rem' }}>
              <Grid item xs={8} sm={8} md={6} style={{ textAlign: `${matchesSM ? 'center' : 'center'}` }}>
                <FormControl disabled={formSubmitted} className={classes.formControl} required={true} fullWidth={true}>
                  <TextField
                    error={etaLocal <= setDateTime()}
                    value={etaLocal}
                    label='Érkezés időpontja'
                    type='datetime-local'
                    SelectProps={{
                      native: true,
                    }}
                    inputProps={{
                      min: `${setDateTime()}`,
                      step: '900'
                    }}
                    onChange={event => {
                      setEtaLocal(event.target.value)
                      if (event.target.value.split("T")[0] !== queryDay) {
                        setQueryDay(event.target.value.split("T")[0])
                      }
                    }}
                  >
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={8} sm={6} md={4} style={{ marginTop: `${matchesXS ? '0.5rem' : '0rem'}`, textAlign: 'left' }}>
                <FormControl disabled={formSubmitted} className={classes.formControl} required={true} fullWidth={true}>
                  <TextField
                    error={shiftDuration === 0}
                    value={shiftDuration}
                    label='Várható időtartam (óra)'
                    type='number'
                    SelectProps={{
                      native: true,
                    }}
                    onChange={event => setShiftDuration(event.target.value)}
                  >
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={10} md={10} style={{ marginTop: '2rem' }}>
              <FormControl disabled={isLoading || formSubmitted} error={errorLocal} component="fieldset" className={classes.formControl}>
                <Grid container direction='row' justify='center' alignItems='center' spacing={0} wrap='wrap' style={{ marginTop: '0.5rem' }}>
                  {techs
                    .filter(tech => singleOption ? tech.email === authedUser.attributes.email : tech)
                    .map((tech, index) => {
                      const relevantHoliday = holidays[queryDay]?.find(holiday => holiday.tech === tech.email.split('@')[0])
                      const startTime = getHolidayStartTime({ holiday: relevantHoliday, queryDay })
                      const endTime = getHolidayEndTime({ holiday: relevantHoliday, queryDay })
                      const shouldDisableDueToHoliday =
                        (relevantHoliday && !relevantHoliday.halfDay)
                        || (relevantHoliday?.halfDay &&
                            new Date(etaLocal).toISOString() >= startTime &&
                            new Date(etaLocal).toISOString() < endTime)
                      return (
                        <Grid key={index} item xs={10} sm={5} style={{ maxWidth: 200 }}>
                          <FormControlLabel
                            disabled={isLoading ||
                                      singleOption ||
                                      arrivals.includes(tech.email.split('@')[0]) ||
                                      shouldDisableDueToHoliday
                                      //|| nightWatches[queryDay]?.tech === tech.email.split('@')[0]
                            }
                            style={{ paddingLeft: '0rem', minWidth: 200 }}
                            className={classes.formControl}
                            control={<Checkbox checked={teamSelected[tech.email.split('@')[0]] || false} onChange={handleTeamChange} name={tech.email.split('@')[0]} />}
                            label={generateConfirmLabel({ queryDay, tech, holidays, shouldDisableDueToHoliday, nightWatches, arrivals })}
                          />
                        </Grid>
                      )
                    })
                  }
                </Grid>
              </FormControl>
            </Grid>
            <Grid item xs={10} sm={8} md={8}>
              <Button
                variant='contained'
                color='primary'
                fullWidth={true}
                style={{ marginTop: '2rem', marginBottom: '1rem' }}
                disabled={(techAdded && etaLocal <= setDateTime()) || (techAdded && !etaLocal) || (techAdded && !shiftDuration) || errorLocal || !teamChanged || formSubmitted}
                startIcon={<CloudUploadIcon />}
                onClick={singleOption ? handleSubmitSingle : handleSubmitEvent}>
                Adatok beküldése
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Fade>
    </Container>
  )
}