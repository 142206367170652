export const errorHandlingWrapper = async (submitFunction, ...params) => {
  try {
    return await submitFunction(...params)
  } catch (error) {
    console.log(error)
    if (error.code === 'NetworkError' || error.message === 'Network Error' || error.errors[0].message === "Network Error") {
      throw new Error('NetworkError')
    } else if (error.errors[0].errorType === 'MissingDataError') {
      console.log(error.errors[0].message)
      throw new Error('MissingDataError')
    } else if (error.errors[0].errorType === 'UnauthorizedError') {
      throw new Error('UnauthorizedError')
    } else if (error.response?.status === 401 || error.message === 'authenticationError') {
      console.log('ERROR: ', error.response.status, error.response.data.message)
      throw new Error('authenticationError')
    } else if (error.errors[0].errorType === "DataNotFoundError") {
      throw new Error('DataNotFoundError')
    } else if (error.errors[0].errorType === 'ConditionalCheckFailedException') {
      throw new Error('ConditionalCheckFailedException')
    } else {
      throw new Error("UnidentifiedError")
    }
  }
}