import React, { useState } from 'react'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'


export const WSUpload = props => {

  const { wsImage, setWsImage, formSubmitted } = props

  const [fileSizeError, setFileSizeError] = useState(false)

  return (
    <Grid container direction='row' justify='space-around' alignItems='center' spacing={2} style={{marginTop: '1rem'}}>
      <Grid item xs={12} sm={12} md={12} lg={5} style={{ textAlign: 'center' }}>
        <Button
          variant='outlined'
          color={wsImage ? 'secondary' : 'primary'}
          disabled={formSubmitted}
          >
          <label htmlFor='ws-upload'>
            {!wsImage ? 'File kiválasztása' : 'Kiválasztás módosítása'}
            <input
              id='ws-upload'
              type="file"
              accept='application/pdf, image/*'
              onChange={event => {
                if (event.target.files.length === 0) {
                  setWsImage('')
                } else if (event.target.files[0].size > 2.8 * 2**20) {
                  setWsImage('')
                  setFileSizeError(true)
                } else {
                  setWsImage(event.target.files[0])
                  setFileSizeError(false)
                }
              }}
              style={{ display: 'none' }}
              />
          </label>
        </Button>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={7} style={{ textAlign: 'center' }}>
        <TextField
          error={!wsImage}
          variant='standard'
          fullWidth
          value={wsImage ? wsImage.name : 'Nincs kiválasztott fájl.'}
          disabled
          helperText={fileSizeError && 'A maximális feltölthető fileméret 2.8 MB. Kérlek válassz ennek megfelelően.'}
        />
      </Grid>
    </Grid>
  )
}