import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Fade from '@material-ui/core/Fade'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import Alert from '@material-ui/lab/Alert'

import { TicketingContext } from '../TicketingContext'
import { UploadProgress } from './Subcomponents/UploadProgress'
import { generatePayload } from './helper/generatePayload'
import { EventPageHeader } from './Subcomponents/EventPageHeader'

export const Jelen = props => {

  const { submitEvent, errorType, setErrorType, classes, theme, ticketDetails } = useContext(TicketingContext)
  const { ticketId } = useParams()

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  const [counterState, setCounterState] = useState(ticketDetails[ticketId].ticketData.counterState || Number())
  const [upLoading, setUpLoading] = useState(true)
  const [formSubmitted, setFormSubmitted] = useState(false)

  const handleSubmitEvent = async () => {
    setFormSubmitted(true)
    setUpLoading(true)
    try {
      const payLoad = generatePayload({ eventType: props.location.pathname.split('/').slice(-1)[0].toUpperCase(), ticketId })
      if (!ticketDetails[ticketId].ticketData.counterState && ticketDetails[ticketId].ticketData.clientId !== 'SSPT') payLoad.counterState = counterState

      await submitEvent({ payLoad, ticketId, setUpLoading, history: props.history })

    } catch (error) {
      setErrorType(error.message)
      setUpLoading(false)
    }
  }


  return (
    <Container style={{ padding: 0, margin: 'auto' }}>
      <EventPageHeader ticketId={ticketId} options={ticketDetails[ticketId].options} />
      <Fade in={true} timeout={{ enter: 500 }}>
        <Paper elevation={8} square style={{ margin: 0, padding: '1rem', overflow: 'hidden' }}>
          {formSubmitted &&
            <UploadProgress upLoading={upLoading} errorType={errorType} setErrorType={setErrorType} setFormSubmitted={setFormSubmitted} classes={classes} theme={theme} />
          }
          <Typography variant='h5' align='center' color='primary' style={{ marginBottom: '2rem', marginTop: '2rem', fontStyle: 'bold' }}>Helyszínre érkezés jelzése</Typography>
          <Grid container justify='center' alignItems='center' direction='column'>
            <Grid container direction='column' item xs={5}>
              <TextField
                required={true}
                fullWidth={true}
                type="number"
                id="counterState"
                disabled={ticketDetails[ticketId].ticketData.counterState != null && ticketDetails[ticketId].ticketData.counterState !== 0}
                value={counterState}
                onKeyPress={event => event.key === 'Enter' && event.preventDefault()}
                onChange={event => setCounterState(parseInt(event.target.value))}
                label="Számlálóállás"
                variant="standard"
              />
              {((counterState && Number.isNaN(counterState)) || (counterState && !Number.isInteger(counterState)) || counterState === 0 || counterState.toString().includes('.')) &&
                <Alert severity="error" style={{ textAlign: 'left' }}>A számlálóállás mezőt pozitív egész számmal kell kitöltened.</Alert>}
              {ticketDetails[ticketId].ticketData.counterState &&
                <Alert severity="info" style={{ textAlign: 'left' }}>A számlálóállást korábban érkezett kolléga már kitöltötte.</Alert>}
            </Grid>
            <Grid item xs={10} sm={8} md={6}>
              <Button
                variant='contained'
                color='primary'
                fullWidth={true}
                style={{ marginTop: '2rem', marginBottom: '1rem' }}
                disabled={(ticketDetails[ticketId].ticketData.clientId !== 'SSPT' && (!counterState || (counterState && Number.isNaN(counterState)) || (counterState && !Number.isInteger(counterState)) || counterState === 0)) || formSubmitted}
                startIcon={<CloudUploadIcon />}
                onClick={handleSubmitEvent}>
                Adatok beküldése
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Fade>
    </Container>
  )
}