import React, { Fragment } from 'react'
import { ticketStatusMap } from '../../data/ticketStatusMapping'

import Typography from '@material-ui/core/Typography'

export const EventPageHeader = props => {

  const { ticketId, options } = props

  return (
    <Fragment>
      <Typography variant='h6' align='center' color='primary' style={{ marginBottom: '0rem', marginTop: '1rem', fontWeight: 'bold' }}>{ticketId}</Typography>
      <Typography variant='subtitle1' align='center' color='primary' style={{ marginBottom: '0rem', marginTop: '0rem' }}>{options && `Státusz: ${ticketStatusMap[options.ticketStatus]}`}</Typography>
      <Typography variant='body1' align='center' color='textPrimary' style={{ marginBottom: '1rem', marginTop: '0rem' }}>{(options && (options.isAssigned ? 'Hozzá vagy rendelve' : 'Nem vagy hozzárendelve'))}</Typography>
    </Fragment>
  )
}