import React from 'react'
import { Auth } from 'aws-amplify'
import Button from '@material-ui/core/Button'

export const SignOutButton = () => {
  
  const handleSignOut = async () => {
    Auth.signOut()
  }

  return (
    <Button
      variant='outlined'
      color='primary'
      style={{ maxWidth: '10rem', maxHeight: '2rem', marginRight: '1rem', marginLeft: '1rem', marginTop: '0.5rem', marginBottom: '0.5rem' }}
      onClick={handleSignOut}
      >
      Kilépés
    </Button>
  )
}