import React, { Fragment } from 'react'

// UI elements
import Backdrop from '@material-ui/core/Backdrop'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Fab from '@material-ui/core/Fab'
import CheckIcon from '@material-ui/icons/Check'
import WifiOffOutlinedIcon from '@material-ui/icons/WifiOffOutlined'
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

export const UploadProgress = props => {

  const { classes, theme, upLoading, errorType, setErrorType, setFormSubmitted } = props

  return (
    <Backdrop open={true} className={classes.backdrop} >
      <Paper elevation={8} square className={classes.progressModal} style={{ margin: 'auto', padding: '1rem', textAlign: 'center', border: 'none' }}>
        <Grid container direction='column' justify='center' alignItems='center'>
          {upLoading && <CircularProgress size={53} style={{ color: `${theme.palette.primary.light}` }} />}
          {!upLoading && !errorType &&
            <Fab style={{ backgroundColor: `${theme.palette.success.main}` }}>
              <CheckIcon style={{ color: 'white' }} />
            </Fab>
          }
          {!errorType &&
            <Typography variant='h6' style={{ margin: '1rem', textAlign: 'center' }}>
              {upLoading ? 'Adatok beküldése...' : 'Sikeres beküldés!'}
            </Typography>
          }
          {errorType === 'NetworkError' &&
            <Fab style={{ backgroundColor: `${theme.palette.error.dark}` }}>
              <WifiOffOutlinedIcon fontSize='large' style={{ color: 'white' }} />
            </Fab>
          }
          {(errorType === 'UnidentifiedError' || errorType === 'UnauthorizedError') &&
            <HighlightOffOutlinedIcon fontSize='large' style={{ fontSize: 60, color: `${theme.palette.error.dark}` }} />
          }
          {errorType === 'authenticationError' &&
            <ErrorOutlineIcon fontSize='large' style={{ fontSize: 60, color: `${theme.palette.warning.dark}` }} />
          }
          {errorType === 'ConditionalCheckFailedException' &&
            <ErrorOutlineIcon fontSize='large' style={{ fontSize: 60, color: `${theme.palette.warning.dark}` }} />
          }
          {errorType === 'DataNotFoundError' &&
            <ErrorOutlineIcon fontSize='large' style={{ fontSize: 60, color: `${theme.palette.warning.dark}` }} />
          }
          {errorType === 'MissingDataError' &&
            <ErrorOutlineIcon fontSize='large' style={{ fontSize: 60, color: `${theme.palette.warning.dark}` }} />
          }
          {errorType &&
            <Fragment>
              <Grid item>
                {/* <p>{errorType}</p> */}
                <Typography variant='h6' style={{ margin: '1rem', textAlign: 'center' }}>
                  {errorType === 'NetworkError' && 'Hiba a hálózati kapcsolatban. Kérem ellenőrizze eszköze internetkapcsolatát, és próbálkozzon újra.'}
                  {errorType === 'ConditionalCheckFailedException' && 'Ugyanazon gépre próbált meg feltölteni rövid időn belül több ticketet. Biztosan ez volt a célja? Ha igen, kérem várjon legalább 1 percet, és próbálkozzon újra.'}
                  {errorType === 'DataNotFoundError' && 'A keresett ticket nem található a rendszerben. Kérem adjon meg érvényes ticketazonosítót vagy válasszon a Ticketek oldalon elérhető ticketek közül.'}
                  {errorType === 'MissingDataError' && 'A kérelem hiányos. Kérem ellenőrizze a megadott adatokat.'}
                  {errorType === 'UnauthorizedError' && 'Nincs jogosultsága a művelet végrehajtásához.'}
                  {errorType === 'authenticationError' && 'Autentikációs hiba. Kérem frissítse az oldalt, és próbálkozzon újra.'}
                  {errorType === 'UnidentifiedError' && 'Váratlan hiba történt.'}
                  {(errorType === 'UnidentifiedError' || errorType === 'UnauthorizedError') && <br />}
                  {(errorType === 'UnidentifiedError' || errorType === 'UnauthorizedError') && 'Kérem, próbálkozzon újra, vagy jelezze a hibát felénk:'}
                  {(errorType === 'UnidentifiedError' || errorType === 'UnauthorizedError') && <br />}
                  {(errorType === 'UnidentifiedError' || errorType === 'UnauthorizedError') && <a href='tel:+3612832426'>+36 1 283 2426</a>}
                </Typography>
              </Grid>
            </Fragment>
          }
          {errorType &&
            <Grid item xs={10}>
              <Button
                color='primary'
                variant='text'
                onClick={() => {
                  setFormSubmitted(false)
                  setErrorType('')
                }}
              >
                OK
              </Button>
            </Grid>
          }
        </Grid>
      </Paper>
    </Backdrop>
  )
}