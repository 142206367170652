import React, { useEffect, useState } from 'react'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import { AvatarComp } from '../Subcomponents/AvatarComp'
import { TicketDetails } from './TicketDetails'
import { TechList } from '../Subcomponents/TechList'
import { WorksheetDownload } from '../Subcomponents/WorksheetDownload'
import { TicketingIconChooser } from './TicketingIconChooser'
import { getEventMessageTexts } from '../helper/getEventMessageTexts'

export const TicketEventCard = props => {

  const { classes, theme, ticketEvent, authedUser } = props

  const [texts, setTexts] = useState('')

  useEffect(() => {
    setTexts(getEventMessageTexts(ticketEvent))
  }, [ticketEvent])

  return (
    <Grid container direction='row' justify='center' alignItems='flex-start' spacing={2}>
      <Grid item xs={2} sm={1} style={{ textAlign: 'center' }}>
        <Grid container direction='column' justify='flex-start' alignItems='center' style={{ marginTop: '1rem' }}>
          <TicketingIconChooser event={ticketEvent.eventType || 'newticket'} size='default' />
          <Typography variant="subtitle1" color='secondary'>{new Date(ticketEvent.dateTime).toLocaleTimeString('hu-HU', { hour: 'numeric', minute: '2-digit', hour12: false })}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={9} sm={10} align='left' style={{ maxWidth: '540px' }}>
        <Card className={props.classes.ticketEventCard}>
          <CardContent>
            <Grid container direction='column' alignItems='center' spacing={2}>
              <Grid container direction='column' item xs={11}>
                <Grid item>
                  <Typography variant="subtitle1" className={`${props.classes.cardTitle} ${props.classes.ticketEventCardTitle}`} gutterBottom color='primary'>{texts.titleText}</Typography>
                </Grid>
                <Grid container direction='row' justify='flex-start' alignItems='center' spacing={1} style={{ marginBottom: '1rem' }}>
                  <AvatarComp classes={classes} theme={theme} displayName={texts && texts.submittedByText} userId={texts && texts.userId} />
                  <Grid item xs={10}>
                    <Typography variant='body2' display='inline' color="textSecondary">
                      <b>{texts.submittedByText}</b> {texts.avatarText}
                    </Typography>
                  </Grid>
                </Grid>
                {texts.statusText &&
                  <Typography variant='body2' color='primary' style={{ marginBottom: '1rem' }}>Ticket státusz: <b>{texts.statusText}</b></Typography>
                }
                {(!ticketEvent.eventType || ['NEW_TICKET', 'MODIFIED_TICKET', 'REPLACED_TICKET'].includes(ticketEvent.eventType)) &&
                  <TicketDetails ticketEvent={ticketEvent} />
                }
                {texts.counterState &&
                  <Typography variant='body2' color="textSecondary" style={{ fontWeight: 'normal', marginBottom: '1rem' }}>Számlálóállás: <b>{texts.counterState}</b></Typography>
                }
                {ticketEvent.eta &&
                  <Typography variant='body2' color="textSecondary" style={{ fontWeight: 'normal', marginBottom: '1rem' }}>Érkezés várható időpontja: <b>{texts.etaDateTimeText}</b></Typography>
                }
                {ticketEvent.techsToBeAdded &&
                  <Grid item>
                    <Typography variant='body2' color="textSecondary" style={{ fontWeight: 'bold' }}>{texts.techsTitleText}</Typography>
                    <TechList techsToBeAdded={ticketEvent.techsToBeAdded} classes={classes} theme={theme} />
                  </Grid>
                }
                {texts.descriptionComment &&
                  <Grid item>
                    <Typography variant='body2' color="textSecondary" style={{ fontWeight: 'bold' }}>{"Megjegyzés a hibához"}</Typography>
                    <Typography variant="body2" color='textSecondary' style={{ marginBottom: '1rem' }}>{texts.descriptionComment}</Typography>
                  </Grid>
                }
                {texts.descriptionText &&
                  <Grid item>
                    <Typography variant='body2' color="textSecondary" style={{ fontWeight: 'bold' }}>{texts.isMessage ? "Üzenet" : ticketEvent.eventType ? "Megjegyzés" : "Hibaleírás"}</Typography>
                    <Typography variant="body2" color='textSecondary'>{texts.descriptionText}</Typography>
                  </Grid>
                }
                {ticketEvent.eventType === 'WORKSHEET' &&
                  <WorksheetDownload WSUrl={ticketEvent.WSUrl} authedUser={authedUser} />
                }
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
} 