import { ssptUsers } from '../data/ssptUsers'

export class Shift {
  constructor({ startTime, endTime, ticketId, tech, arrived, finished, holiday, nightWatch }) {
    const userNameIfOldShift = ssptUsers.find(item => item.userId === tech)?.displayName
    this.userFullName = userNameIfOldShift ? userNameIfOldShift : ssptUsers.find(item => item.email === `${tech}@sspt.hu`).displayName
    this.startTime = startTime
    this.endTime = endTime
    this.durationHours = (new Date(endTime).getTime() - new Date(startTime).getTime()) / 1000 / 60 / 60
    this.ticketId = ticketId
    this.arrived = arrived
    this.finished = finished
    this.holiday = holiday
    this.nightWatch = nightWatch
  }

  toMap() {
    return {
      userFullName: this.userFullName,
      startTime: this.startTime,
      endTime: this.endTime,
      ticketId: this.ticketId,
      arrived: this.arrived,
      finished: this.finished,
      holiday: this.holiday,
      nightWatch: this.nightWatch
    }
  }
}