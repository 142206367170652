import React from 'react'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import { ssptUsers } from '../../data/ssptUsers'

export const Contributors = props => {

  const { authedUser, contributorPool, contributors, setContributors } = props
  
  const handleSwitchChange = (event) => {
    if (event.target.checked) setContributors(prevState => [...prevState, event.target.name])
    else setContributors(prevState => prevState.filter(item => item !== event.target.name))
  }

  return (
    <div style={{ width: '80%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyItems: 'flex-start', alignItems: 'center' }}>
      { contributorPool.sort((a, b) => {
          if (a === authedUser.attributes.email.split('@')[0]) return -1
          if (b === authedUser.attributes.email.split('@')[0]) return 1
          if (a > b) return -1
          else return 1
        })
        .map((contributor, index) =>
        <FormControlLabel
          key={index}
          disabled={contributor === authedUser.attributes.email.split('@')[0]}
          control={
            <Checkbox
              checked={contributors.includes(contributor)}
              onChange={handleSwitchChange}
              name={contributor}
              color="primary"
            />
          }
        label={<Typography variant='body2'>{ssptUsers.find(item => item.email.split('@')[0] === contributor).displayName}</Typography>}
        />
      )
    }
    </div>
  )
}