import { ssptUsers } from '../../data/ssptUsers'
import { ticketStatusMap } from '../../data/ticketStatusMapping'

export const getEventMessageTexts = (updateItem) => {

  const dateTimeText = new Date(updateItem.dateTime).toLocaleTimeString('hu-HU', { hour: '2-digit', minute: '2-digit', hour12: false })//.slice(0,-3).replace(/-/g, '.')
  const etaDateTimeText = updateItem.eta ? new Date(updateItem.eta).toLocaleString('hu-HU').slice(0, -8).replace(/ /g, '') + ' ' + new Date(updateItem.eta).toLocaleTimeString('hu-HU', { hour: 'numeric', minute: '2-digit', hour12: false }) : ''
  const techsTitleText = (updateItem.techsToBeAdded && updateItem.techsToBeAdded.length > 0) ? "A tickethez rendelt szakértők:" : "A tickethez jelenleg nincs szakértő rendelve."
  let submittedByText, userId
  try {
    const ssptUser = ssptUsers.find(user => user.email.split('@')[0] === updateItem.submittedBy)
    if (ssptUser) {
      submittedByText = ssptUser.displayName
      if (ssptUser.noPhoto === true) {
        userId = null
      } else {
        userId = updateItem.submittedBy
      }
    } else {
      submittedByText = updateItem.requestorClient
      userId = null
    }
  } catch (error) {
    console.log('Hiba történt a ticketesemény beküldő azonosítása során: ', error)
    throw (error)
  }
  let statusText = ticketStatusMap[updateItem.ticketStatus] || ''

  let titleText, avatarText, counterState, descriptionText, descriptionComment, isMessage, techList, clientText, machineTypeText, machineText, moduleNameText, requestorText

  switch (updateItem.eventType) {
    case 'CONFIRM':
      titleText = 'Ticket megerősítése'
      avatarText = 'megerősítést küldött a ticketre.'
      techList = true
      break
    case 'ARRIVE':
      titleText = 'Szervíztechnikus megérkezett'
      avatarText = 'megérkezett a helyszínre, és megkezdi a munkát a ticketen.'
      if (updateItem.counterState) counterState = updateItem.counterState
      break
    case 'WORKSHEET':
      titleText = 'Munkalap leadás'
      avatarText = 'munkalapot adott le.'
      if (updateItem.worksheetMessage) descriptionText = updateItem.worksheetMessage
      if (updateItem.descriptionComment) descriptionComment = updateItem.descriptionComment
      break
    case 'MESSAGE_CLIENT':
      titleText = 'Üzenet a szervíztől'
      avatarText = 'üzenetet küldött a tickettel kapcsolatban.'
      isMessage = true
      descriptionText = updateItem.ticketMessage
      break
    case 'CHANGE_STATUS':
      titleText = 'Ticket státusz módosítás'
      avatarText = 'módosította a ticket státuszát.'
      descriptionText = updateItem.ticketStatusMessage
      break
    case 'CHANGE_TEAM':
      titleText = 'Ticket csapat változás'
      avatarText = 'módosította a tickethez rendelt technikusi csapatot.'
      techList = true
      break
    case 'CLIENT_INCOMING':
      titleText = 'Üzenet az ügyféltől'
      avatarText = 'üzenetet küldött a tickettel kapcsolatban.'
      isMessage = true
      descriptionText = updateItem.ticketMessage
      break
    case 'APPROVE_CLOSE':
      titleText = updateItem.clientApproval ? 'Ticket lezárása' : 'Ticket jóváhagyása'
      avatarText = updateItem.clientApproval ? 'jóváhagyást küldött a ticket lezárására.' : 'jóváhagyta a ticket dokumentációt.'
      if (updateItem.ticketStatusMessage) descriptionText = updateItem.ticketStatusMessage
      break
    case 'REJECT_CLOSE':
      titleText = 'Ticket lezárás elutasítva'
      avatarText = 'elutasította a ticket lezárását.'
      isMessage = true
      descriptionText = updateItem.ticketStatusMessage
      break
    case 'APPROVE_CANCEL':
      titleText = 'Ticket lemondása'
      avatarText = 'jóváhagyást küldött a ticket lemondására.'
      if (updateItem.ticketStatusMessage) descriptionText = updateItem.ticketStatusMessage
      break
    case 'REJECT_CANCEL':
      titleText = 'Ticket lemondás elutasítva'
      avatarText = 'elutasította a ticket lemondását.'
      isMessage = true
      descriptionText = updateItem.ticketStatusMessage
      break
    default:
      // default is for the newticket event, which is not represented in the DB record as an eventtype
      // (it gets deleted so that main record changes do not trigger unneeded events)
      titleText = 'Ticket bejelentése'
      avatarText = 'rögzítette a ticketet.'
      statusText = 'NYITOTT'
      clientText = updateItem.clientFullName
      machineTypeText = updateItem.machineType
      machineText = updateItem.machine
      moduleNameText = updateItem.moduleName
      requestorText = updateItem.requestorClient
      descriptionText = updateItem.description
      break
  }

  return {
    dateTimeText,
    submittedByText,
    userId,
    titleText,
    avatarText,
    counterState,
    statusText,
    etaDateTimeText,
    isMessage,
    techList,
    techsTitleText,
    descriptionText,
    descriptionComment,
    clientText,
    machineTypeText,
    machineText,
    moduleNameText,
    requestorText
  }
}