import React, { useEffect, useState } from 'react'

import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import Alert from '@material-ui/lab/Alert'

import { ticketStatusMap } from '../../data/ticketStatusMapping'

const ticketStatusOptions = ['PENDING_CLOSE', 'IN_PROGRESS', 'TO_BE_FINALIZED', 'WAITING_FOR_PARTS', 'WAITING_FOR_OEM_SUPPORT']

export const WSTicketStatusSelector = props => {

  const { ticketStatus, setTicketStatus, formSubmitted, classes, contributorPool, contributors } = props
  
  const [formHelperText, setFormHelperText] = useState('')
  const noManLeftBehind = contributorPool.filter(item => !contributors.includes(item)).length === 0

  useEffect(() => {
    switch (ticketStatus) {
      case 'PENDING_CLOSE':
        setFormHelperText('A problémát elhárítottam, a gép üzemkész.')
        break
      case 'IN_PROGRESS':
        setFormHelperText('A problémát nem sikerült megoldani, további munkára van szükség.')
        break
      case 'TO_BE_FINALIZED':
        setFormHelperText('A gép üzemkész, de a rendes működéshez további munkára van szükség.')
        break
      case 'WAITING_FOR_PARTS':
        setFormHelperText('A gépet hiányzó cserealkatrész nélkül nem tudjuk megjavítani.')
        break
      case 'WAITING_FOR_OEM_SUPPORT':
        setFormHelperText('A géphibához kihívtuk a gyártói szervízt, annak érkezéséig várnunk kell.')
        break
      default:
        break
    }
  }, [ticketStatus])

  return (
    <Grid item xs={10} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
      <FormControl
        error={!ticketStatus}
        className={classes.formControl}
        fullWidth={true}
        >
        <InputLabel>Ticket státusz kiválasztása</InputLabel>
          <Select
            align='left'
            value={ticketStatus}
            disabled={formSubmitted}
            onChange={event => setTicketStatus(event.target.value)}
          >
          { noManLeftBehind
              ? ticketStatusOptions.map(item => (<MenuItem align='left' key={item} value={item}>{ticketStatusMap[item]}</MenuItem>))
              : <MenuItem align='left' key={0} value='IN_PROGRESS'>FOLYAMATBAN</MenuItem>
          }
        </Select>
        <FormHelperText>{formHelperText}</FormHelperText>
        {!noManLeftBehind && <Alert severity="warning" align='left'>Nem adtál hozzá minden, a tickethez rendelt és már megérkezett kollégát a munkalaphoz, így csak FOLYAMATBAN státuszt választhatsz.</Alert>}
      </FormControl>
    </Grid>
  )
}