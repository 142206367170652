import React, { useEffect, useState, Fragment } from 'react'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import { ticketTypeMap } from '../../data/ticketTypeMap'


export const WSTicketDataComp = props => {

  const { classes, clients, ticketData, ticketDataItem, clientMachines, switchStates, moduleError, setModuleError, moduleNameState, setModuleNameState } = props

  const [moduleOptions, setModuleOptions] = useState('')

  useEffect(() => {
    if (ticketDataItem === 'moduleName' && clientMachines && ticketData) {
      const getModuleList = (clientMachines, ticketData) => {
        const list = clientMachines
          .filter(machineItem => machineItem.SearchID.startsWith(`${ticketData.clientId}#${ticketData.machineType}#${ticketData.machine}`))
          .map(item => `${item.ModuleName ? item.ModuleName : ""}${item.ModuleNumber ? ` ${item.ModuleNumber}` : ""}`)
          .map(item => (!item ? "-" : item))
          .sort()
        setModuleOptions(list)
      }
      getModuleList(clientMachines, ticketData)
    }
  }, [clientMachines, ticketData, ticketDataItem])

  useEffect(() => {
    if (!moduleNameState && moduleOptions) setModuleNameState(ticketData[ticketDataItem])
  }, [moduleOptions, moduleNameState, ticketData, ticketDataItem, setModuleNameState])

  useEffect(() => {
    if (ticketDataItem === 'moduleName' && moduleOptions.length !== 1 && moduleNameState === '-' && !switchStates['moduleName']) setModuleError(true)
    else if (ticketDataItem === 'moduleName') setModuleError(false)
  }, [ticketDataItem, moduleError, moduleOptions, moduleNameState, switchStates, setModuleError])

  let inputLabelText, fieldVariant, smSelection, mdSelection
  switch (ticketDataItem) {
    case 'clientFullName':
      inputLabelText = 'Ügyfél'
      break
    case 'ticketType':
      inputLabelText = 'Ticket típusa'
      break
    case 'machineType':
      inputLabelText = 'Gépsor / típus'
      break
    case 'machine':
      inputLabelText = 'Gép'
      break
    case 'requestorClient':
      inputLabelText = 'Bejelentő / igénylő'
      break
    case 'counterState':
      inputLabelText = 'Számlálóállás'
      break
    case 'moduleName':
      inputLabelText = 'Modul'
      break
    case 'description':
      inputLabelText = 'Hiba leírása (eredeti)'
      smSelection = 10
      mdSelection = 10
      break
    default:
      break
  }

  return (
    <Fragment>
      { ((ticketData.clientId !== "SSPT" && clientMachines) || ticketData.clientId === "SSPT") &&
        <Grid item xs={10} sm={smSelection ? smSelection : 5} md={mdSelection ? mdSelection : 5}>
          { ticketDataItem !== 'moduleName' &&
            <TextField
            fullWidth={true}
            disabled={true}
            type="text"
            value={ticketDataItem === 'clientFullName'
              ? clients[ticketData.clientId]
              : (ticketDataItem === 'ticketType'
                ? ticketTypeMap[ticketData[ticketDataItem]]
                : ticketData[ticketDataItem]
              )
            || '-'}
            label={inputLabelText}
            variant={fieldVariant ? fieldVariant : 'standard'}
            />
          }
          { ticketDataItem === 'moduleName' && moduleOptions &&
            <FormControl
            className={classes.formControl}
            fullWidth={true}
            error={moduleError}
            >
              <InputLabel>{inputLabelText}</InputLabel>
              <Select
                align='left'
                value={moduleNameState}
                disabled={switchStates['moduleName']}
                onChange={event => setModuleNameState(event.target.value)}
                >
                {moduleOptions.map(item => (
                  <MenuItem align='left' key={item} value={item}>{item}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          }
        </Grid>
      }
    </Fragment>
  )
}