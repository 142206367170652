import React, { useState, useEffect, useCallback } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import LinearProgress from '@material-ui/core/LinearProgress'

// Subcomponents
import { AddTicket } from './components/AddTicket'
import { ProtectedRoute } from './components/ProtectedRoute'
import { ResponsiveDrawer } from './TicketingAppBar'
import { DashBoard } from './components/DashBoard'
import { Jelen } from './components/Jelen'
import { Uzenet } from './components/Uzenet'
import { Munkalap } from './components/Munkalap'
import { Jelzes } from './components/Jelzes'
import { Statusz } from './components/Statusz'
import { Tickets } from './components/Tickets'
import { Ticket } from './components/Ticket'
import { Shift } from './models/Shift'
import { optionDetails } from './data/dashboardOptions'
import {
  getTicketData,
  getTickets,
  getMachinesCall,
  getShiftsCall,
  listContacts,
  listClientsCall,
  getNightWatchAndHolidaysCall
} from './requests/ticketRequest'
import { getStringFromDate } from './components/helper/getStringFromDate'
import { Shifts } from './components/Shifts'
import { Assigned } from './components/Assigned'
import { ssptUsers } from './data/ssptUsers'
import { TicketingContext } from './TicketingContext'
import { errorHandlingWrapper } from './components/helper/errorHandlingWrapper'
import { handleEventSubmission } from './components/helper/handleEventSubmission'
import { Fragment } from 'react'

const kDayHourArray = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]
const kNightHourArray = [17, 18, 19, 20, 21, 22, 23, 24, 1, 2, 3, 4, 5, 6, 7]
const today = new Date()

export const Internal = props => {
  
  const { authedUser, classes, theme } = props

  const [clients, setClients] = useState({})
  const [ticketData, setTicketData] = useState('')
  const [ticketDetails, setTicketDetails] = useState({})
  const [contacts, setContacts] = useState([])
  const [clientMachines, setClientMachines] = useState('')
  const [shifts, setShifts] = useState({})
  const [nightWatches, setNightWatches] = useState({})
  const [holidays, setHolidays] = useState({})
  const [loadingSuccess, setLoadingSuccess] = useState(true)
  const [childActivation, setChildActivation] = useState(false)
  const [tickets, setTickets] = useState('')
  const [assignedTickets, setAssignedTickets] = useState('')
  const [ticketCounts, setTicketCounts] = useState({ open: 0, confirmed: 0, in_progress: 0, waiting: 0, rejected: 0, approve: 0 })
  const [lastStatusSelection, setLastStatusSelection] = useState('approve')
  const [onTicketPage, setOnTicketPage] = useState(false)
  const [queryDay, setQueryDay] = useState(getStringFromDate(today))
  const [hoursArray, setHoursArray] = useState(kDayHourArray)
  const [errorType, setErrorType] = useState('')
  
  const toggleNightShift = () => {
    if (hoursArray === kDayHourArray) {
      setHoursArray(kNightHourArray)
    } else {
      setHoursArray(kDayHourArray)
    }
  }

  const listClients = async () => {
    try {
      const results = await errorHandlingWrapper(listClientsCall)
      const clientsMap = {}
      results.map(clientItem => clientsMap[clientItem.clientID] = clientItem.clientName)
      setClients(clientsMap)
      return results
    } catch (error) {
      setErrorType(error.message)
    }
  }

  const refreshTickets = async () => {
    try {
      setLoadingSuccess(false)
      const response = await errorHandlingWrapper(getTickets)
      setTickets(response.listTickets)
      setLoadingSuccess(true)
    } catch (error) {
      setErrorType(error.message)
    }
  }
  
  const refreshAssigned = async () => {
    try {
      setLoadingSuccess(false)
      const response = await errorHandlingWrapper(getTickets, true)
      setTickets(response.listTickets)
      setAssignedTickets(response.getAssignedTickets)
      setLoadingSuccess(true)
    } catch (error) {
      setErrorType(error.message)
    }
  }

  const refreshTicketDetails = async (ticketId) => {
    try {
      setLoadingSuccess(false)
      const response = await errorHandlingWrapper(getTicketData, ticketId)
      setTicketDetails({ [ticketId]: response })
      setLoadingSuccess(true)
    } catch (error) {
      setErrorType(error.message)
      setLoadingSuccess(true)
    }
  }

  const getContacts = async (clientID) => {
    try {
      setLoadingSuccess(false)
      const data = await errorHandlingWrapper(listContacts, clientID)
      setContacts(data.listContacts)
      setLoadingSuccess(true)
    } catch (error) {
      setErrorType(error.message)
    }
  }

  const getClientMachines = async (clientID, userCo, authedUser) => {
    try {
      setLoadingSuccess(false)
      const data = await errorHandlingWrapper(getMachinesCall, clientID, userCo)
      if (clientID !== "SSPT") {
        setClients({ [clientID]: data.listMachines[0].ClientName })
        setClientMachines(data.listMachines)
      } else {
        setClients({ SSPT: "Smart Solutions Papírtechnológia Kft." })
      }
      setContacts(data.listContacts ? data.listContacts : [authedUser.attributes.name])
      setLoadingSuccess(true)
    } catch (error) {
      setErrorType(error.message)
    }
  }
  
  const getNightWatchAndHolidays = async ({queryDay}) => {
    try {
      setLoadingSuccess(false)
      const response = await errorHandlingWrapper(getNightWatchAndHolidaysCall, queryDay)
      const nightWatchTemp = { [queryDay]: response.getRelevantNightWatch }
      const holidaysTemp = { [queryDay]: response.getRelevantHolidays }
      setNightWatches({ ...nightWatches, ...nightWatchTemp })
      setHolidays({ ...holidays, ...holidaysTemp })
      setLoadingSuccess(true)
    } catch (error) {
      setErrorType(error.message)
      throw (error)
    }
  }
  
  const getShifts = async (queryDay) => {
    try {
      setLoadingSuccess(false)
      const response = await errorHandlingWrapper(getShiftsCall, queryDay)
      const nightWatchTemp = { [queryDay]: response.getRelevantNightWatch }
      const holidaysTemp = { [queryDay]: response.getRelevantHolidays }
      setNightWatches({ ...nightWatches, ...nightWatchTemp })
      setHolidays({ ...holidays, ...holidaysTemp })
      const shiftsTemp = {}
      shiftsTemp[queryDay] = {}
      ssptUsers.filter(item => item.tech)
        .forEach(tech => {
          shiftsTemp[queryDay][tech.email.split('@')[0]] =
            response.getShiftsByDay.filter(item => item.tech === tech.email.split('@')[0])
              .map(item => new Shift({
                startTime: item.startTime,
                endTime: item.endTime,
                ticketId: item.ticketId,
                tech: item.tech,
                arrived: item.arrived,
                finished: item.finished,
                holiday: false,
                nightWatch: false
              }))
              .sort((a, b) => (a.startTime > b.startTime) ? 1 : -1)
        })
      setShifts({ ...shifts, ...shiftsTemp })
      setLoadingSuccess(true)
    } catch (error) {
      setErrorType(error.message)
      throw (error)
    }
  }

  const submitEvent = async ({ payLoad, ticketId, setUpLoading, history }) => {
    try {
      await handleEventSubmission({
        ticketDetails, setTicketDetails,
        ticketId,
        tickets, setTickets,
        payLoad,
        authedUser,
        setUpLoading,
        setLoadingSuccess,
        history
      })
      await refreshAssigned()
    } catch (error) {
      setErrorType(error.message)
    }
  }

  const generateTicketCounts = useCallback((tickets, setTicketCounts) => {
    if (tickets) {
      const ticketCountsTemp = { open: 0, confirmed: 0, in_progress: 0, waiting: 0, rejected: 0, approve: 0 }
      tickets.forEach(item => {
        switch (item.ticketStatus) {
          case 'OPEN':
          case 'PLANNED':
          case 'REOPENED':
            ticketCountsTemp.open++
            break
          case 'CONFIRMED':
            ticketCountsTemp.confirmed++
            break
          case 'IN_PROGRESS':
            ticketCountsTemp.in_progress++
            break
          case 'WAITING_FOR_PARTS':
          case 'TO_BE_FINALIZED':
          case 'WAITING_FOR_OEM_SUPPORT':
            ticketCountsTemp.waiting++
            break
          case 'REJECTED_CLOSE':
          case 'REJECTED_CANCEL':
            ticketCountsTemp.rejected++
            break
          case 'PENDING_CLOSE':
          case 'PENDING_CANCEL':
            ticketCountsTemp.approve++
            break
          case 'CLOSED':
          case 'CANCELLED':
            break
          default:
            throw new Error('Ticketstatus unknown.')
        }
      })
      setTicketCounts(ticketCountsTemp)
    }
  }, [])

  useEffect(() => {
    generateTicketCounts(tickets, setTicketCounts)
  }, [tickets, lastStatusSelection, generateTicketCounts])

  return (
    <Fragment>
      { authedUser.attributes &&
        <TicketingContext.Provider value={{
          authedUser,
          ssptAdmin: parseInt(authedUser.attributes['custom:admin']) === 1,
          userCo: authedUser.attributes['custom:company'],
          approver: parseInt(authedUser.attributes['custom:manager']) === 1 || parseInt(authedUser.attributes['custom:admin']) === 1,
          classes,
          theme,
          optionDetails,
          kDayHourArray,
          clients, listClients,
          errorType, setErrorType,
          ticketData, setTicketData,
          ticketDetails, setTicketDetails,
          contacts, setContacts, getContacts,
          clientMachines, setClientMachines,
          shifts, setShifts,
          loadingSuccess, setLoadingSuccess,
          childActivation, setChildActivation,
          tickets, setTickets,
          assignedTickets, setAssignedTickets,
          ticketCounts, setTicketCounts,
          lastStatusSelection, setLastStatusSelection,
          onTicketPage, setOnTicketPage,
          queryDay, setQueryDay,
          hoursArray, setHoursArray,
          holidays, nightWatches,
          getNightWatchAndHolidays,
          submitEvent,
          toggleNightShift,
          refreshTickets,
          refreshAssigned,
          refreshTicketDetails,
          getClientMachines,
          getShifts,
        }}>
          <BrowserRouter>
            <ResponsiveDrawer />
            {(childActivation || !loadingSuccess) &&
              <LinearProgress style={{ marginTop: '0rem', zIndex: 2 }} />
            }
            <Switch>
              <ProtectedRoute exact path='/newticket' component={AddTicket} />
              <Route exact path='/ticketek' render={(routerProps) => (<Tickets {...routerProps} />)} />
              <Route exact path='/ticketek/:ticketId' render={(routerProps) => (<Ticket {...routerProps} />)} />
              {authedUser.attributes['custom:company'] === 'SSPT' && <Route exact path='/assigned' render={(routerProps) => (<Assigned {...routerProps} />)} />}
              {authedUser.attributes['custom:company'] === 'SSPT' && <Route exact path='/muszakok' render={(routerProps) => (<Shifts {...routerProps} />)} />}
              {authedUser.attributes['custom:company'] === 'SSPT' && <ProtectedRoute exact path='/ticketek/:ticketId/events' component={DashBoard} />}
              {authedUser.attributes['custom:company'] === 'SSPT' && <ProtectedRoute exact path='/ticketek/:ticketId/events/arrive' component={Jelen} />}
              {authedUser.attributes['custom:company'] === 'SSPT' && <ProtectedRoute exact path='/ticketek/:ticketId/events/worksheet' component={Munkalap} />}
              {authedUser.attributes['custom:company'] === 'SSPT' && <ProtectedRoute exact path='/ticketek/:ticketId/events/message_client' component={Uzenet} />}
              {authedUser.attributes['custom:company'] === 'SSPT' && <ProtectedRoute exact path='/ticketek/:ticketId/events/confirm' component={Jelzes} />}
              {authedUser.attributes['custom:company'] === 'SSPT' && <ProtectedRoute exact path='/ticketek/:ticketId/events/change_team' component={Jelzes} />}
              {authedUser.attributes['custom:company'] === 'SSPT' && <ProtectedRoute exact path='/ticketek/:ticketId/events/change_status' component={Statusz} />}
              {authedUser.attributes['custom:company'] === 'SSPT' &&
                parseInt(authedUser.attributes['custom:admin']) === 1 &&
                <Redirect from='/SSPT' to={{ pathname: '/newticket', state: { clientId: 'SSPT' } }} />
              }
              {authedUser.attributes['custom:company'] === 'SSPT' &&
                parseInt(authedUser.attributes['custom:admin']) !== 1 &&
                <Redirect from='/' to={{ pathname: '/assigned' }} />
              }
              {!(authedUser.attributes['custom:company'] === 'SSPT' &&
                parseInt(authedUser.attributes['custom:admin']) !== 1) &&
                <Redirect from='/' to={{ pathname: '/newticket' }} />
              }
            </Switch>
          </BrowserRouter>
        </TicketingContext.Provider>
      }
    </Fragment>
  )
}