import API from '@aws-amplify/api'
import Axios from 'axios'
import { errorHandlingWrapper } from '../components/helper/errorHandlingWrapper'
const baseEndPoint = process.env.REACT_APP_BASE_ENDPOINT

// Auth related wrapper function
export const authWrapper = async (authedUser, apiRequest, ...params) => {

  const refreshSession = () => {
    return new Promise((resolve, reject) => {
      authedUser.refreshSession(authedUser.signInUserSession.refreshToken, (error, response) => {
        if (error) {
          console.log('Session refresh unsuccessful')
          reject(new Error('authenticationError'))
        } else {
          resolve(response)
        }
      })
    })
  }

  try {
    if (authedUser.signInUserSession.idToken.payload.exp * 1000 < Date.now()) {
      const authResponse = await errorHandlingWrapper(refreshSession)
      return await errorHandlingWrapper(apiRequest, authResponse.idToken.jwtToken, ...params)
    } else {
      return await errorHandlingWrapper(apiRequest, authedUser.signInUserSession.idToken.jwtToken, ...params)
    }
  } catch (error) {
    if (error.name === 'TypeError') {
      throw new Error('serverSide')
    } else {
      throw (error)
    }
  }
}

export const getNightWatchAndHolidaysCall = async (queryDay) => {
  const response = await API.graphql({ query: `
    query GetNightWatchAndHolidays {
      getRelevantNightWatch(queryDay: "${queryDay}") {
        partitionKey
        sortKey
        tech
      }
      getRelevantHolidays(queryDay: "${queryDay}") {
        endDate
        partitionKey
        sortKey
        startDate
        tech
        sickness
        halfDay
        morning
      }
    }
  ` })
  return response.data
}

export const getShiftsCall = async (queryDay) => {
  const response = await API.graphql({ query: `
    query GetShiftsByDay {
      getShiftsByDay(queryDay: "${queryDay}") {
        arrived
        endTime
        finished
        startTime
        tech
        ticketId
      }
      getRelevantNightWatch(queryDay: "${queryDay}") {
        partitionKey
        sortKey
        tech
      }
      getRelevantHolidays(queryDay: "${queryDay}") {
        endDate
        partitionKey
        sortKey
        startDate
        tech
        sickness
        halfDay
        morning
      }
    }
  ` })
  return response.data
}

export const getTicketData = async (ticketId) => {
  const response = await API.graphql({ query: `
    query GetTicketData {
      getTicket(ticketId: "${ticketId}") {
        ticketData {
          partitionKey
          sortKey
          machine
          clientApproval
          clientId
          confirmedAt
          counterState
          dateTime
          description
          descriptionComment
          eventType
          machineType
          moduleName
          machineID
          machineSearchID
          replaces
          requestedClosingAt
          requestorClient
          requestorEmail
          ssptApproval
          startedAt
          submittedBy
          ticketId
          ticketStatus
          ticketType
        }
        arrivals
        techs
        options {
          events
          hasAdminRights
          isAssigned
          hasArrived
          statusOptions
          ticketStatus
        }
        ticketEvents {
          WSUrl
          clientApproval
          contributors {
            tech
          }
          counterState
          dateTime
          descriptionComment
          eta
          eventType
          moduleName
          requestorClient
          shiftDuration
          ssptApproval
          startDateTime
          submittedBy
          techsToBeAdded
          techsToBeRemoved
          ticketMessage
          ticketStatus
          ticketStatusMessage
          userCompany
          worksheetMessage
        }
      }
    }
  ` })
  return response.data.getTicket
}

export const getTickets = async (assigned = false) => {
  let response
  const listTicketsQueryString = `
    listTickets {
      partitionKey
      sortKey
      machine
      clientApproval
      clientId
      confirmedAt
      counterState
      dateTime
      description
      descriptionComment
      eventType
      machineType
      moduleName
      machineID
      machineSearchID
      replaces
      requestedClosingAt
      requestorClient
      requestorEmail
      ssptApproval
      startedAt
      submittedBy
      ticketId
      ticketStatus
      ticketType
    }`
  if (assigned) {
    response = await API.graphql({ query: `
      query ListTickets {
        getAssignedTickets
        ${listTicketsQueryString}
      }
    ` })
    return response.data
  } else {
    response = await API.graphql({ query: `
    query ListTickets {
      ${listTicketsQueryString}
    }
    ` })
    return response.data
  }
}


//  Worksheet upload related requests

export const getPutWSSignedUrl = async (idToken, clientId, ticketId, fileType, WSDateTime) => {
  const response = await Axios.get(`${baseEndPoint}/events/wssignedurl?clientId=${clientId}&ticketId=${ticketId}&fileType=${fileType}&WSDateTime=${WSDateTime}`, {
    headers: {
      'authorization': `Bearer ${idToken}`
    }
  })
  return response
}

export const getGetWSSignedUrl = async (idToken, WSUrl) => {
  const response = await Axios.get(`${baseEndPoint}/events/wssignedurl?WSUrl=${WSUrl}`, {
    headers: {
      'authorization': `Bearer ${idToken}`
    }
  })
  return response
}

export const downloadWorksheet = async (signedUrl) => {
  return await Axios.get(signedUrl)
}

export const uploadWorksheet = async (signedUrl, worksheetFile) => {
  return await Axios.put(signedUrl, worksheetFile)
}


// Teams ticket events related requests

export const submitTicketEvent = async (ticketEventData) => {
  const response = await API.graphql({ query: `
  mutation TicketEvent($ticketEventData: TicketEventInput!) {
    ticketEvents(ticketEvent: $ticketEventData) {
      arrivals
      options {
        ticketStatus
        statusOptions
        isAssigned
        hasArrived
        hasAdminRights
        events
      }
      statusUpdateObject {
        machine
        clientApproval
        confirmedAt
        counterState
        descriptionComment
        machineType
        moduleName
        machineID
        machineSearchID
        requestedClosingAt
        ssptApproval
        startedAt
        ticketStatus
      }
      techs
      updateItem {
        GSI2PK
        worksheetMessage
        userCompany
        ticketStatusMessage
        ticketStatus
        ticketMessage
        ticketId
        techsToBeRemoved
        techsToBeAdded
        submittedBy
        startDateTime
        ssptApproval
        shiftDuration
        requestorClient
        sortKey
        partitionKey
        moduleName
        machineID
        machineSearchID
        eventType
        eta
        descriptionComment
        dateTime
        counterState
        contributors {
          tech
        }
        clientApproval
        WSUrl
      }
      shiftUpdates {
        partitionKey
        sortKey
        ticketId
        tech
        startTime
        endTime
        arrived
        finished
      }
      deletedShifts {
        partitionKey
        sortKey
      }
    }
  }
  `,
    variables: {
      ticketEventData
    }
  })
  return response
}

export const getAdminsCall = async (idToken) => {
  const response = await Axios.get(`${baseEndPoint}/admin`, {
    headers: {
      'authorization': `Bearer ${idToken}`,
      'Content-Type': 'application/json',
    }
  })
  return response
}

export const setAdminsCall = async (idToken, selectedAdmin) => {
  const response = await Axios.patch(`${baseEndPoint}/admin/current`, JSON.stringify({ selectedAdmin }), {
    headers: {
      'authorization': `Bearer ${idToken}`,
      'Content-Type': 'application/json',
    }
  })
  return response
}


// Ticket uploading requests

export const createTicket = async (newItem) => {
  const response = await API.graphql({
    query:
    `mutation CreateTicket($newItem: NewTicketRequest!) {
        createTicket(newItem: $newItem) {
          created {
            partitionKey
            sortKey
            clientApproval
            clientId
            counterState
            dateTime
            description
            localDateTime
            machine
            machineType
            moduleName
            machineID
            machineSearchID
            eventType
            requestorClient
            requestorEmail
            ssptApproval
            submittedBy
            ticketId
            ticketStatus
            ticketType
          }
          deleted {
            partitionKey
            sortKey
          }
        }
      }
    `,
    variables: {
      newItem
    }
  })
  return response.data.createTicket
}

export const modifyTicket = async (originalItem, newItem, ticketId) => {
  const response = await API.graphql({
    query: 
    `mutation PatchTicket($newItem: NewTicketRequest!, $originalItem: NewTicketRequest!, $ticketId: String!) {
      patchTicket(newItem: $newItem, originalItem: $originalItem, ticketId: $ticketId) {
        created {
          partitionKey
          sortKey
          clientApproval
          clientId
          counterState
          dateTime
          description
          localDateTime
          machine
          machineType
          moduleName
          machineID
          machineSearchID
          eventType
          requestorClient
          requestorEmail
          ssptApproval
          submittedBy
          ticketId
          ticketStatus
          ticketType
        }
        deleted {
          partitionKey
          sortKey
        }
      }
    }`,
    variables: {
      newItem,
      originalItem,
      ticketId
    }
  })
  return response.data.patchTicket
}

export const listClientsCall = async () => {
  const response = await API.graphql({
    query: 
    `query listClients {
      listClients {
        clientID
        clientName
      }
    }`
  })
  return response.data.listClients
}

export const listContacts = async (clientID) => {
  const response = await API.graphql({
    query: 
    `query listContacts($clientID: String!) {
      listContacts(clientID: $clientID)
    }`,
    variables: {
      clientID
    }
  })
  return response.data
}

export const getMachinesCall = async (clientID, userCo) => {
  let response
  if (userCo === "SSPT") {
    response = await API.graphql({
      query: 
      `query GetClientMachines($clientID: String!) {
        listContacts(clientID: $clientID)
        listMachines(clientID: $clientID) {
          ClientName
          Location
          Machine
          MachineSerial
          MachineType
          ModuleName
          ModuleNumber
          SearchID
          partitionKey
          sortKey
        }
      }`,
      variables: {
        clientID
      }
    })
  } else {
    response = await API.graphql({
      query: 
      `query GetClientMachines($clientID: String!) {
        listMachines(clientID: $clientID) {
          ClientName
          Location
          Machine
          MachineSerial
          MachineType
          ModuleName
          ModuleNumber
          SearchID
          partitionKey
          sortKey
        }
      }`,
      variables: {
        clientID
      }
    })
  }
  return response.data
}