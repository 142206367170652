const { ticketTypeMap } = require("../../data/ticketTypeMap")

module.exports = {

  determineTicketTypeOptions: (clientId, authedUser) => {
    if (clientId === 'SSPT') {
      return [
        ticketTypeMap.SSPT_REFURBISH,
        ticketTypeMap.SSPT_TRANSPORT,
        ticketTypeMap.SSPT_MANUFACTURE,
        ticketTypeMap.SSPT_MISC
      ]
    } else if (authedUser.attributes['custom:company'] === 'SSPT' || parseInt(authedUser.attributes['custom:manager']) === 1) {
      return [
        ticketTypeMap.FAULT,
        ticketTypeMap.SUPERVISION,
        ticketTypeMap.PLANNED_MAINTENANCE,
        ticketTypeMap.RECONFIGURE,
        ticketTypeMap.INSTALLATION
      ]
    } else {
      return [ticketTypeMap.FAULT, ticketTypeMap.SUPERVISION]
    }
  },

  getClients: function (clients, userCo) {
    if (userCo !== 'SSPT' && !clients) clients[userCo] = userCo
    else clients["SSPT"] = "Smart Solutions Papírtechnológia Kft."
    return Object.keys(clients)
  },

  getMachineTypes: function (clientMachines, clientId) {
    if (clientMachines && clientId) return Array.from(new Set(clientMachines.map(item => item.MachineType)))
    else return []
  },

  getMachines: function (clientMachines, clientId, machineType) {
    if (clientMachines && clientId && machineType) {
      const relevantMachines = clientMachines.filter(item => item.MachineType === machineType)
      const uniqueMachines = {}
      relevantMachines.forEach(item => {
        if (!uniqueMachines[item.Machine]) {
          uniqueMachines[item.Machine] = { machine: item.Machine, serial: item.MachineSerial }
        }
      })
      return uniqueMachines
    } else return {}
  },

  getModules: function (clientMachines, clientId, machineType, machine) {
    // as modules are optional, an empty option is added here if option count !== 1
    if (clientMachines && clientId && machineType && machine) {
      const options = Array.from(new Set(clientMachines
        .filter(item => item.MachineType === machineType && item.Machine === machine)
        .map(item => (item.ModuleNumber ? `${item.ModuleName} ${item.ModuleNumber}` : item.ModuleName))))
        .map(item => item ? item : "-")
        return options
    }
    else return []
  }

}