import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import TextField from '@material-ui/core/TextField'

import { UploadProgress } from './Subcomponents/UploadProgress'
import { generatePayload } from './helper/generatePayload'
import { Title } from './Subcomponents/Title'
import { WSUpload } from './Subcomponents/WSUpload'
import { SwitchComp } from './Subcomponents/SwitchComp'
import { WSTicketStatusSelector } from './Subcomponents/WSTicketStatusSelector'

import { authWrapper, getPutWSSignedUrl, uploadWorksheet } from '../requests/ticketRequest'
import { DividerTitle } from './Subcomponents/DividerTitle'
import { WSTicketDataComp } from './Subcomponents/WSTicketDataComp'
import { Contributors } from './Subcomponents/Contributors'
import { EventPageHeader } from './Subcomponents/EventPageHeader'

import { TicketingContext } from '../TicketingContext'
import { errorHandlingWrapper } from './helper/errorHandlingWrapper'

export const Munkalap = props => {

  const { submitEvent, errorType, setErrorType, classes, theme, authedUser, setLoadingSuccess, ticketDetails, clients, clientMachines, userCo, getClientMachines } = useContext(TicketingContext)
  const { ticketId } = useParams()

  useEffect(() => {
    window.scroll(0, 0)
  }, [])
  const [moduleError, setModuleError] = useState(ticketDetails[ticketId].ticketData.moduleName ? true : false)
  const [moduleNameState, setModuleNameState] = useState('')
  const [switchStates, setSwitchStates] = useState({ moduleName: false, descriptionComment: false })
  const [descriptionComment, setDescriptionComment] = useState('')
  const [descriptionCommentError, setDescriptionCommentError] = useState(true)
  const [worksheetMessage, setWorksheetMessage] = useState('')
  const [ticketStatus, setTicketStatus] = useState('')
  const [upLoading, setUpLoading] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [wsImage, setWsImage] = useState('')
  const [contributorPool] = useState(ticketDetails[ticketId].arrivals)
  const [contributors, setContributors] = useState([authedUser.attributes.email.split('@')[0]])
  const [fetchActive, setFetchActive] = useState(false)

  useEffect(() => {
    if (errorType) {
      setLoadingSuccess(true)
    }
  }, [errorType, setLoadingSuccess])

  useEffect(() => {
    try {
      if (!clientMachines && !fetchActive) {
        setFetchActive(true)
        getClientMachines(ticketDetails[ticketId].ticketData.clientId, userCo, authedUser)
      }
    } catch (error) {
      setErrorType(error.message)
    }
  }, [authedUser, clientMachines, fetchActive, getClientMachines, setErrorType, ticketDetails, ticketId, userCo])

  useEffect(() => {
    if (clientMachines && fetchActive) setFetchActive(false)
  }, [clientMachines, fetchActive])

  useEffect(() => {
    if ((!descriptionComment && !switchStates.descriptionComment) || (descriptionComment.length < 15 && !switchStates.descriptionComment)) setDescriptionCommentError(true)
    else setDescriptionCommentError(false)
  }, [descriptionComment, switchStates.descriptionComment])


  const handleSubmitEvent = async () => {
    setFormSubmitted(true)
    setUpLoading(true)

    let WSUrl
    const clientId = ticketDetails[ticketId].ticketData.clientId
    try {
      const fileType = wsImage.type.split('/').slice(1)[0]
      const WSDateTime = new Date().toISOString().replace(/:/g, '-')
      const signedUrlResponse = await authWrapper(authedUser, getPutWSSignedUrl, clientId, ticketId, fileType, WSDateTime)
      await errorHandlingWrapper(uploadWorksheet, signedUrlResponse.data.S3Url, wsImage)
      WSUrl = signedUrlResponse.data.S3Url.split('?')[0]
    } catch (error) {
      console.log('An unexpected error has occurred while uploading worksheet file to S3: ', error)
      setErrorType(error.message)
      setUpLoading(false)
    }

    try {
      const payLoad = generatePayload({ eventType: props.location.pathname.split('/').slice(-1)[0].toUpperCase(), ticketId })
      if (moduleNameState && !switchStates.moduleName) {
        payLoad.moduleName = moduleNameState
        const potentialMachines = clientMachines.filter(item =>
          item.SearchID === `${clientId}#${ticketDetails[ticketId].ticketData.machineType}#${ticketDetails[ticketId].ticketData.machine}${moduleNameState === "-" ? "" : `#${moduleNameState.replace(" ", "-")}`}`
        )
        if (potentialMachines.length !== 1) {
          throw new Error("Could not identify selected machine based on form content. This is a data model problem; please contact SSPT.")
        } else {
          payLoad.machineID = potentialMachines[0].sortKey
          payLoad.machineSearchID = potentialMachines[0].SearchID
        }
      }
      if (descriptionComment && !switchStates.descriptionComment) payLoad.descriptionComment = descriptionComment
      payLoad.worksheetMessage = worksheetMessage
      payLoad.ticketStatus = ticketStatus
      payLoad.WSUrl = WSUrl
      payLoad.contributors = contributors.map(contributor => ({ tech: contributor }))

      await submitEvent({ payLoad, ticketId, setUpLoading, history: props.history })

    } catch (error) {
      console.log('An error has occured:\n', error)
      setErrorType(error.message)
      setUpLoading(false)
    }
  }

  return (
    <Container style={{ padding: 0, margin: 'auto' }}>
      <EventPageHeader ticketId={ticketId} options={ticketDetails[ticketId].options} />
      { ((ticketDetails[ticketId].ticketData.clientId !== "SSPT" && clientMachines) || ticketDetails[ticketId].ticketData.clientId === "SSPT") &&
        <Fade in={true} timeout={{ enter: 500 }}>
          <Paper elevation={8} square style={{ margin: 0, padding: '1rem', overflow: 'hidden' }}>
            {(formSubmitted || errorType) &&
              <UploadProgress upLoading={upLoading} errorType={errorType} setErrorType={setErrorType} setFormSubmitted={setFormSubmitted} classes={classes} theme={theme} />
            }
            <Title title='Munkalap beadás' />
            <Grid container justify='center' alignItems='center' direction='row' spacing={2}>
              <DividerTitle lineColor={theme.palette.primary.main} title='1. Ticket adatok pontosítása' />
              <WSTicketDataComp classes={classes} clients={clients} ticketData={ticketDetails[ticketId].ticketData} clientMachines={clientMachines} ticketDataItem='clientFullName' />
              <WSTicketDataComp classes={classes} ticketData={ticketDetails[ticketId].ticketData} clientMachines={clientMachines} ticketDataItem='ticketType' />
              {ticketDetails[ticketId].ticketData.machineType &&
                <WSTicketDataComp classes={classes} ticketData={ticketDetails[ticketId].ticketData} clientMachines={clientMachines} ticketDataItem='machineType' />
              }
              {ticketDetails[ticketId].ticketData.machine &&
                <WSTicketDataComp classes={classes} ticketData={ticketDetails[ticketId].ticketData} clientMachines={clientMachines} ticketDataItem='machine' />
              }
              <WSTicketDataComp classes={classes} ticketData={ticketDetails[ticketId].ticketData} clientMachines={clientMachines} ticketDataItem='requestorClient' />
              {ticketDetails[ticketId].ticketData.counterState &&
                <WSTicketDataComp classes={classes} ticketData={ticketDetails[ticketId].ticketData} clientMachines={clientMachines} ticketDataItem='counterState' />
              }
              {ticketDetails[ticketId].ticketData.moduleName &&
                <WSTicketDataComp classes={classes} ticketData={ticketDetails[ticketId].ticketData} ticketDataItem='moduleName'
                  clientMachines={clientMachines}
                  moduleError={moduleError}
                  setModuleError={setModuleError}
                  moduleNameState={moduleNameState}
                  setModuleNameState={setModuleNameState}
                  switchStates={switchStates}
                />
              }
              {ticketDetails[ticketId].ticketData.moduleName && (moduleError || switchStates['moduleName']) &&
                <Grid item xs={10} sm={5} md={5}>
                  <SwitchComp switchStates={switchStates} setSwitchStates={setSwitchStates} stateVariableName='moduleName' />
                </Grid>
              }
              <WSTicketDataComp classes={classes} ticketData={ticketDetails[ticketId].ticketData} ticketDataItem='description' />
              <Grid item xs={10} sm={10} md={10}>
                <TextField
                  align='left'
                  error={descriptionCommentError}
                  multiline
                  rows={1}
                  rowsMax={10}
                  disabled={switchStates.descriptionComment}
                  fullWidth={true}
                  value={descriptionComment}
                  onChange={event => {
                    setDescriptionComment(event.target.value)
                  }}
                  label="Megjegyzés hibaleíráshoz"
                  variant="standard"
                />
              </Grid>
              {(descriptionCommentError || switchStates['descriptionComment']) &&
                <Grid item xs={10} sm={10} md={10}>
                  <SwitchComp switchStates={switchStates} setSwitchStates={setSwitchStates} stateVariableName='descriptionComment' />
                </Grid>
              }
              <DividerTitle lineColor={theme.palette.primary.main} title='2. Munkalap adatok' />
              <Grid item xs={10} sm={10} md={10}>
                <WSUpload wsImage={wsImage} setWsImage={setWsImage} formSubmitted={formSubmitted} />
              </Grid>
              <Grid item xs={10} sm={10} md={10}>
                <TextField
                  multiline
                  rows={1}
                  rowsMax={10}
                  disabled={formSubmitted}
                  fullWidth={true}
                  value={worksheetMessage}
                  onChange={event => {
                    setWorksheetMessage(event.target.value)
                  }}
                  label="Üzenet munkalap leadáshoz"
                  variant="standard"
                />
              </Grid>
              <DividerTitle lineColor={theme.palette.primary.main} title='3. Közreműködők' />
              <Contributors authedUser={authedUser} contributorPool={contributorPool} contributors={contributors} setContributors={setContributors} />
              <DividerTitle lineColor={theme.palette.primary.main} title='4. Ticket státusz' />
              <WSTicketStatusSelector contributorPool={contributorPool} contributors={contributors} ticketStatus={ticketStatus} setTicketStatus={setTicketStatus} formSubmitted={formSubmitted} classes={classes} />
              <Grid item xs={10} sm={8} md={8}>
                <Button
                  variant='contained'
                  color='primary'
                  fullWidth={true}
                  style={{ marginTop: '2rem', marginBottom: '1rem' }}
                  disabled={formSubmitted || !wsImage || moduleError || descriptionCommentError || !ticketStatus}
                  startIcon={<CloudUploadIcon />}
                  onClick={handleSubmitEvent}>
                  Munkalap beküldése
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Fade>
      }
    </Container>
  )
}