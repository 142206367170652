import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Fade from '@material-ui/core/Fade'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import TextField from '@material-ui/core/TextField'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { UploadProgress } from './Subcomponents/UploadProgress'
import { generatePayload } from './helper/generatePayload'
import { EventPageHeader } from './Subcomponents/EventPageHeader'

import { TicketingContext } from '../TicketingContext'
import { ticketStatusMap } from '../data/ticketStatusMapping'

export const Statusz = props => {

  const { submitEvent, errorType, setErrorType, classes, theme, ticketDetails } = useContext(TicketingContext)
  const { ticketId } = useParams()

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  const ticketStatusOptions = ticketDetails[ticketId].options.statusOptions.map(item => item.toUpperCase())

  const [ticketStatus, setTicketStatus] = useState('')
  const [ticketStatusMessage, setTicketStatusMessage] = useState('')
  const [upLoading, setUpLoading] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)

  const handleSubmitEvent = async () => {
    setFormSubmitted(true)
    setUpLoading(true)
    try {
      const payLoad = generatePayload({ eventType: props.location.pathname.split('/').slice(-1)[0].toUpperCase(), ticketId })
      payLoad.ticketStatus = ticketStatus
      payLoad.ticketStatusMessage = ticketStatusMessage

      await submitEvent({ payLoad, ticketId, setUpLoading, history: props.history })

    } catch (error) {
      console.log('An error has occured:\n', error)
      setErrorType(error.message)
      setUpLoading(false)
    }
  }

  return (
    <Container style={{ padding: 0, margin: 'auto' }}>
      <EventPageHeader ticketId={ticketId} options={ticketDetails[ticketId].options} />
      <Fade in={true} timeout={{ enter: 500 }}>
        <Paper elevation={8} square style={{ margin: 0, padding: '1rem', overflow: 'hidden' }}>
          {formSubmitted &&
            <UploadProgress upLoading={upLoading} errorType={errorType} setErrorType={setErrorType} setFormSubmitted={setFormSubmitted} classes={classes} theme={theme} />
          }
          <Typography variant='h5' align='center' color='primary' style={{ marginBottom: '2rem', marginTop: '2rem', fontStyle: 'bold' }}>Ticket státusz módosítása</Typography>
          <Grid container justify='center' alignItems='center' direction='column'>
            <Grid item xs={10}>
              <Typography variant='body1' align='center' className={classes.ticketText} style={{ marginBottom: '0rem', fontStyle: 'normal' }}>{`Az alábbiakban tudod módosítani a ticket státuszát a rendszerben.`}</Typography>
              <Typography variant='body1' align='center' className={classes.ticketText} style={{ marginBottom: '1rem', fontStyle: 'normal' }}>{`(${ticketStatusOptions.length} opció közül választhatsz, mert a többi státuszt a rendszer az események alapján maga állítja be):`}</Typography>
            </Grid>
            <Grid item xs={10}>
              <FormControl required component="fieldset" disabled={formSubmitted} className={classes.formControl}>
                <RadioGroup name="ticketStatus" value={ticketStatus} onChange={event => setTicketStatus(event.target.value)}>
                  {ticketStatusOptions.map((option, index) => (
                    <FormControlLabel style={{ marginBottom: '0rem' }} key={option} value={option} control={<Radio />} label={`${ticketStatusMap[option]}`} />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={10}>
              <Typography variant='body1' align='center' className={classes.ticketText} style={{ textAlign: 'left', marginTop: '1rem', marginBottom: '0rem', fontStyle: 'normal' }}>Ide írd a státusz változáshoz kapcsolódó üzeneted:</Typography>
              <form className={classes.root} noValidate autoComplete="on">
                <TextField
                  multiline
                  rows={5}
                  rowsMax={10}
                  required={true}
                  disabled={formSubmitted}
                  fullWidth={true}
                  value={ticketStatusMessage}
                  onChange={event => {
                    setTicketStatusMessage(event.target.value)
                  }}
                  label="Üzenet ticket státusz módosításhoz"
                  variant="outlined"
                />
              </form>
            </Grid>
            <Grid item xs={10}>
              <Button
                variant='contained'
                color='primary'
                fullWidth={true}
                style={{ marginTop: '2rem', marginBottom: '1rem' }}
                disabled={!ticketStatus || !ticketStatusMessage || formSubmitted}
                startIcon={<CloudUploadIcon />}
                onClick={handleSubmitEvent}>
                Státusz módosítás beküldése
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Fade>
    </Container>
  )
}