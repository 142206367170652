import React, { useContext } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ReplayIcon from '@material-ui/icons/Replay';
import Fade from '@material-ui/core/Fade'

import { AddTicketContext } from './AddTicket'
import { TicketingContext } from '../TicketingContext'

const paramHu = [
  'Ügyfél',
  'Típus:',
  'Időpont',
  'Gépsor / típus',
  'Gép azonosító',
  'Modul',
  'Számlálóállás',
  'Bejelentő',
]

const formItems = [
  'clientId',
  'ticketType',
  'ticketDateTimePlaceHolder',
  'machineType',
  'machine',
  'moduleName',
  'counterState',
  'requestorClient',
]


export const TicketSuccess = props => {

  const { classes, theme } = useContext(TicketingContext)
  const { modifyWindowPassed, ticketId, formState, setSuccessPage, modification, setModification, setFormStateOriginal } = useContext(AddTicketContext)

  const ticketDateTimeText = ticketId.split('_')[2].replace(/-/g, '.').replace('T', ' ').slice(0, -4) + ticketId.slice(-4, -2) + ':' + ticketId.slice(-2)

  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Fade in={true} timeout={{ enter: 500 }}>
      <Grid container spacing={matchesXS ? 2 : 1}>
        <Grid item xs={12}>
          <Typography variant='h6' align='center'>
            Az Ön ticketszáma:<br />
          </Typography>
          <Typography variant='h5' align='center' color='primary' style={{ marginBottom: '2rem', fontStyle: 'italic' }}>
            {ticketId}
          </Typography>
        </Grid>
        <Grid container spacing={matchesSM ? 3 : 6} justify='center' alignItems={matchesSM ? 'center' : 'flex-start'}>
          <Grid item xs={10} sm={10} md={10} lg={5} style={{ width: '100%' }}>
            <Typography variant='subtitle1' align='left' style={{ fontWeight: 'bold' }}>
              Ticket adatok
            </Typography>
            <Divider variant="middle" style={{ width: '100%', margin: 'auto' }} />
            <TableContainer component={'div'}>
              <Table className={classes.table} size='small' style={{ width: '80%', margin: 'auto' }}>
                <TableBody>
                  {paramHu.map((item, index) => (
                    <TableRow key={item}>
                      <TableCell align='left' style={{ fontWeight: 'bold', borderBottom: 'none' }}>{item}</TableCell>
                      <TableCell align='left' style={{ borderBottom: 'none' }}>
                        {paramHu[index] === 'Időpont'
                          ? ticketDateTimeText
                          : formState[formItems[index]]
                            ? formState[formItems[index]]
                            : '-'
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={10} sm={10} md={10} lg={5} style={{ width: '100%' }}>
            <Typography variant='subtitle1' align='left' style={{ fontWeight: 'bold' }}>
              Hibaleírás
            </Typography>
            <Divider variant="middle" style={{ width: '100%', margin: 'auto' }} />
            <Typography variant='body1' align='left' style={{ overflowWrap: 'break-word' }}>
              {formState.description}
            </Typography>
          </Grid>
          <Grid item xs={10} sm={10} md={10} style={{ width: '100%' }}>
            <Typography variant='body1' align='center' color='primary' style={{ fontWeight: 'bold', marginTop: `${matchesSM ? '2rem' : '0rem'}`, lineHeight: '1.5' }}>
              A bejelentett hibát rögzítettük, intézkedünk a javításról.
            </Typography>
            <Typography variant='body1' align='center' color='primary' style={{ fontWeight: 'normal', marginBottom: '2rem', lineHeight: '1.5' }}>
              {!modification && 'Ha mindent rendben talált a feltöltött tickettel, bezárhatja az ablakot.'}
            </Typography>
            <Typography variant='body1' align='center' color='primary' style={{ fontStyle: 'italic', fontWeight: 'normal', marginBottom: '2rem', lineHeight: '1.5' }}>
              Üdvözlettel: SSPT szervíz csapat
            </Typography>
          </Grid>
        </Grid>
        {!modification && !modifyWindowPassed &&
          <Grid container direction='column' alignItems='center'>
            <Grid item xs={10}>
              <Typography variant='body2' align='center' style={{ fontWeight: 'normal', textAlign: 'center' }}>
                Elfelejtett valamit? Pontosítana? 1 percen belül van rá lehetősége.
              </Typography>
              <Button
                variant='outlined'
                color='primary'
                fullWidth={true}
                style={{ marginBottom: '2rem' }}
                startIcon={<ReplayIcon />}
                onClick={() => {
                  window.scroll(0, 0)
                  setSuccessPage(false)
                  setModification(true)
                  setFormStateOriginal({ ...formState })
                }}
              >Ticket módosítása
              </Button>
            </Grid>
          </Grid>
        }
      </Grid>
    </Fade>
  )
}