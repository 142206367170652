export const getWeekDay = (date) => {
  const weekDays = {
    0: 'Vasárnap',
    1: 'Hétfő',
    2: 'Kedd',
    3: 'Szerda',
    4: 'Csütörtök',
    5: 'Péntek',
    6: 'Szombat'
  }
  return weekDays[date.getDay()]
}