import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'

export const SwitchComp = props => {

  const { switchStates, setSwitchStates, stateVariableName } = props

  const handleSwitchChange = (event) => {
    setSwitchStates({ ...switchStates, [event.target.name]: event.target.checked });
  };

  let labelText
  switch (stateVariableName) {
    case 'moduleName':
      labelText = 'A hiba helye modulszinten nem határozható meg'
      break
    case 'descriptionComment':
      labelText = 'Nincs kiegészítenivalóm a hibaleíráshoz'
      break
    default:
      break
  }

  return (
    <FormControlLabel
    control={
      <Checkbox
        checked={props.switchStates[stateVariableName]}
        onChange={handleSwitchChange}
        name={stateVariableName}
        color="primary"
      />
    }
  label={<Typography variant='body2'>{labelText}</Typography>}
  />
  )
}