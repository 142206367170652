export const optionDetails = [
  {
    event: 'ARRIVE',
    label: 'Szervíztechnikus',
    title: 'Megérkezés jelzése',
    description: 'Itt jelezheted, hogy megérkeztél az ügyfél telephelyére, és megkezded a munkát.'
  },
  {
    event: 'WORKSHEET',
    label: 'Szervíztechnikus',
    title: 'Munkalap leadás jelzése',
    description: 'Itt jelezheted, hogy befejezted a munkát, és leadtad a munkalapot.'
  },
  {
    event: 'MESSAGE_CLIENT',
    label: 'Szervíztechnikus',
    title: 'Üzenet küldése az ügyfélnek',
    description: 'Itt tudsz üzenetet küldeni az ügyfélnek a tickettel kapcsolatban.'
  },
  {
    event: 'CONFIRM',
    label: 'Koordinátor',
    title: 'Megerősítés és kiosztás',
    description: 'Itt tudsz megerősítést küldeni a ticketre az érkezés idejével és a hozzárendelt kollégákkal.'
  },
  {
    event: 'CHANGE_TEAM',
    label: 'Koordinátor',
    title: 'Csapat módosítása',
    description: 'Tickethez rendelt csapat módosítása.'
  },
  {
    event: 'CHANGE_STATUS',
    label: 'Koordinátor',
    title: 'Státusz módosítása',
    description: 'Csak olyan esetekre, amiket esemény-vezérelten a rendszer nem állít be.'
  }
]