import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import CssBaseline from '@material-ui/core/CssBaseline'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import AddIcon from '@material-ui/icons/Add'
import ListAltIcon from '@material-ui/icons/ListAlt'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import EventNoteIcon from '@material-ui/icons/EventNote'
import RouterOutlinedIcon from '@material-ui/icons/RouterOutlined';

import { TicketingContext } from './TicketingContext'
import { AccountPopover } from './AccountPopover'

export const ResponsiveDrawer = props => {

  const { authedUser, userCo, ssptAdmin, classes, theme } = useContext(TicketingContext)

  const history = useHistory()
  const location = useLocation()
  const matchesMD = useMediaQuery(theme.breakpoints.up('md'))
  const matchesXL = useMediaQuery(theme.breakpoints.up('xl'))

  const [popoverAnchor, setPopoverAnchor] = useState(null)
  const [mainTabSelection, setMainTabSelection] = useState(('/' + history.location.pathname.split('/')[1] === '/') ? '/newticket' : '/' + history.location.pathname.split('/')[1])
  const accountButtonClick = (event) => {
    setPopoverAnchor(event.currentTarget)
  }
  const handlePopoverClose = () => {
    setPopoverAnchor(null)
  }

  const handleMainTabSelection = (_, newValue) => {
    setMainTabSelection((newValue === '/') ? '/newticket' : newValue)
  }

  useEffect(() => {
    handleMainTabSelection(0, (location.pathname === '/') ? '/newticket' : '/' + location.pathname.split('/')[1])
  }, [location])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" color='primary' style={{
        width: '100%',
        marginLeft: 0
      }}>
        <Toolbar variant='dense' style={{ display: 'flex', flexDirection: 'row' }}>
          <Typography variant="h6" align='left' noWrap>{matchesXL ? 'SSPT Ticketing' : ''}</Typography>
          <Tabs
            style={{ flexGrow: 1 }}
            value={mainTabSelection}
            classes={{
              indicator: classes.indicator
            }}
            textColor="inherit"
            onChange={handleMainTabSelection}
            centered
          >
            {(userCo !== 'SSPT' || (userCo === 'SSPT' && ssptAdmin)) &&
              <Tab style={{ minWidth: 60 }} onClick={() => history.push('/')} label={<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start' }}><AddIcon fontSize='default' color='inherit' /><div style={{ width: matchesMD ? 5 : 0 }}></div><div>{matchesMD && 'Új ticket'}</div></div>} value='/newticket' />
            }
            {userCo === 'SSPT' &&
              <Tab style={{ minWidth: 60 }} onClick={() => history.push('/assigned')} label={<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start' }}><RouterOutlinedIcon fontSize='default' color='inherit' /><div style={{ width: matchesMD ? 10 : 0 }}></div><div>{matchesMD && 'Hívásaid'}</div></div>} value='/assigned' />
            }
            <Tab style={{ minWidth: 60 }} onClick={() => history.push('/ticketek')} label={<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start' }}><ListAltIcon fontSize='default' color='inherit' /><div style={{ width: matchesMD ? 10 : 0 }}></div><div>{matchesMD && 'Ticketek'}</div></div>} value='/ticketek' />
            {userCo === 'SSPT' && ssptAdmin &&
              <Tab style={{ minWidth: 60 }} onClick={() => history.push('/muszakok')} label={<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start' }}><EventNoteIcon fontSize='default' color='inherit' /><div style={{ width: matchesMD ? 10 : 0 }}></div><div>{matchesMD && 'Műszakok'}</div></div>} value='/muszakok' />
            }
          </Tabs>
          <div onClick={accountButtonClick} style={{ justifyContent: 'flex-end', display: 'flex', flexDirection: 'row' }}>
            {matchesXL &&
              <Typography variant="subtitle1" style={{ marginRight: '1rem', alignSelf: 'center', userSelect: 'none' }}>{authedUser.attributes.name}</Typography>
            }
            <AssignmentIndIcon fontSize='default' />
          </div>
        </Toolbar>
      </AppBar>
      <Toolbar variant='dense' style={{ marginBottom: 0 }} />
      <AccountPopover authedUser={authedUser} userCo={userCo} anchorEl={popoverAnchor} onClose={handlePopoverClose} />
    </div >
  )
}