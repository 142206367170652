import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Fade from '@material-ui/core/Fade'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import TextField from '@material-ui/core/TextField'

import { TicketingContext } from '../TicketingContext'
import { UploadProgress } from './Subcomponents/UploadProgress'
import { generatePayload } from './helper/generatePayload'
import { EventPageHeader } from './Subcomponents/EventPageHeader'

export const Uzenet = props => {

  const { submitEvent, errorType, setErrorType, classes, theme, setLoadingSuccess, ticketDetails } = useContext(TicketingContext)
  const { ticketId } = useParams()

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  useEffect(() => {
    if (errorType) {
      setLoadingSuccess(true)
    }
  }, [errorType, setLoadingSuccess])

  const [ticketMessage, setTicketMessage] = useState('')
  const [upLoading, setUpLoading] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)

  const handleSubmitEvent = async () => {
    setFormSubmitted(true)
    setUpLoading(true)
    try {
      const payLoad = generatePayload({ eventType: props.location.pathname.split('/').slice(-1)[0].toUpperCase(), ticketId })
      payLoad.ticketMessage = ticketMessage

      await submitEvent({ payLoad, ticketId, setUpLoading, history: props.history })

    } catch (error) {
      console.log('An error has occured:\n', error)
      setErrorType(error.message)
      setUpLoading(false)
    }
  }

  return (
    <Container style={{ padding: 0, margin: 'auto' }}>
      <EventPageHeader ticketId={ticketId} options={ticketDetails[ticketId].options} />
      <Fade in={true} timeout={{ enter: 500 }}>
        <Paper elevation={8} square style={{ margin: 0, padding: '1rem', overflow: 'hidden' }}>
          {formSubmitted &&
            <UploadProgress upLoading={upLoading} errorType={errorType} setErrorType={setErrorType} setFormSubmitted={setFormSubmitted} classes={classes} theme={theme} />
          }
          <Typography variant='h5' align='center' color='primary' style={{ marginBottom: '2rem', marginTop: '2rem', fontStyle: 'bold' }}>Üzenet küldése az ügyfélnek</Typography>
          <Grid container justify='center' alignItems='center' direction='column'>
            <Grid item xs={10} sm={8} md={6}>
              <Typography variant='subtitle1' align='left' className={classes.ticketText} style={{ marginTop: '1rem', marginBottom: '1rem', fontStyle: 'normal' }}>Ide tudod beírni, hogy milyen üzenetet szeretnél küldeni az ügyfélnek a tickettel kapcsolatban (nem jár státuszváltozással):</Typography>
              <form className={classes.root} noValidate autoComplete="on">
                <TextField
                  multiline
                  rows={5}
                  rowsMax={10}
                  required={false}
                  disabled={formSubmitted}
                  fullWidth={true}
                  value={ticketMessage}
                  onChange={event => {
                    setTicketMessage(event.target.value)
                  }}
                  label="Üzenet tickethez kapcsolódóan"
                  variant="outlined"
                />
              </form>
            </Grid>
            <Grid item xs={10} sm={8} md={6}>
              <Button
                variant='contained'
                color='primary'
                fullWidth={true}
                style={{ marginTop: '2rem', marginBottom: '1rem' }}
                disabled={!ticketMessage || formSubmitted}
                startIcon={<CloudUploadIcon />}
                onClick={handleSubmitEvent}>
                Ticket üzenet beküldése
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Fade>
    </Container>
  )
}