import React, { useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

import { AvatarComp } from './AvatarComp'
import { Shift } from '../../models/Shift'
import { getHolidayEndTime, getHolidayStartTime } from '../helper/getHolidayTimes'

const shiftRowHeight = 85

const doesOverlap = (shift1, shift2) => {
  return !(shift1.startTime >= shift2.endTime || shift2.startTime >= shift1.endTime)
}

const getAllHours = (shiftList) => {
  if (shiftList.length === 0) {
    return 0
  } else {
    let hours = 0
    let remaining = Array.from(shiftList)
    while (remaining.length > 0) {
      let remainingConnected = Array.from(remaining)
      let startTime
      let endTime
      while (remainingConnected.length > 0) {
        startTime = remainingConnected[0].startTime
        endTime = remainingConnected[0].endTime
        // eslint-disable-next-line no-loop-func
        const candidates = remainingConnected.filter(item => item.startTime > startTime && item.startTime < endTime && item.endTime > endTime)
        if (candidates.length === 0) {
          break
        } else {
          endTime = candidates.reduce((a, b) => a.endTime > b.endTime ? a : b).endTime
          // eslint-disable-next-line no-loop-func
          remainingConnected = remainingConnected.filter(item => item.startTime > startTime && item.startTime < endTime && item.endTime > endTime)
        }
      }
      hours += (new Date(endTime).getTime() - new Date(startTime).getTime()) / 1000 / 60 / 60
      remaining = remaining.filter(item => item.startTime > endTime)
    }
    return hours
  }
}

export const ShiftRow = props => {

  const history = useHistory()
  const { classes, theme, displayName, userId, tech, status, shifts, holiday, nightWatch, hoursArray, scheduleBoxWidth, onlyInitials, sidebarWidth, queryDay } = props
  const [rows, setRows] = useState('')
  const [numbers, setNumbers] = useState('')

  const filterShifts = useCallback((shift) => {
    const dayStartDateString = new Date(new Date(queryDay.split('-')[0], queryDay.split('-')[1] - 1, queryDay.split('-')[2]).getTime() + (hoursArray[0]) * 1000 * 60 * 60).toISOString()
    const dayEndDateString = new Date(new Date(queryDay.split('-')[0], queryDay.split('-')[1] - 1, queryDay.split('-')[2]).getTime() + (hoursArray[0] + (hoursArray.length - 1)) * 1000 * 60 * 60).toISOString()
    if (!(
      (shift.startTime >= dayStartDateString && shift.startTime <= dayEndDateString) ||
      (shift.endTime >= dayStartDateString && shift.endTime <= dayEndDateString) ||
      (shift.startTime < dayStartDateString && shift.endTime > dayEndDateString)
    )) {
      return false
    } else {
      return true
    }
  }, [hoursArray, queryDay])

  useEffect(() => {
    let rows = {}
    let index = 0
    if (holiday || nightWatch) {
      const specialShift = new Shift({
        startTime: getHolidayStartTime({ holiday, queryDay }),
        endTime: getHolidayEndTime({ holiday, queryDay }),
        ticketId: holiday ? (holiday.sickness === true ? "BETEGSZABADSÁG" : "SZABADSÁG") : "ÜGYELET",
        tech,
        arrived: false,
        finished: false,
        holiday: holiday ? true : false,
        nightWatch: nightWatch ? true : false
      })
      rows[index] = [specialShift]
      index++
    }
    let rowTemp = []
    let nonOverlapCounter = 0
    let processTemp = Array.from(shifts)
    while (processTemp.length > 0) {
      rowTemp.push(processTemp[0])
      processTemp = processTemp.slice(1)
      for (let shift of processTemp) {
        nonOverlapCounter = 0
        for (let item of rowTemp) {
          if (doesOverlap(shift, item)) {
            break
          } else {
            nonOverlapCounter++
          }
        }
        if (nonOverlapCounter === rowTemp.length) {
          rowTemp.push(shift)
        }
      }
      rows[index] = Array.from(rowTemp)
      // eslint-disable-next-line no-loop-func
      processTemp = processTemp.filter(item => !rowTemp.includes(item))
      rowTemp = []
      index++
    }
    setNumbers(getAllHours(shifts.filter(shift => filterShifts(shift))))
    setRows(rows)
  }, [shifts, displayName, hoursArray, filterShifts, holiday, nightWatch, queryDay, tech])

  return (
    <Grid container direction='row' wrap='nowrap' alignItems='center'>
      <Box width={sidebarWidth}>
        {onlyInitials &&
          <Grid container direction='column' justify='center' alignItems='center'>
            <Grid item>
              <AvatarComp classes={classes} theme={theme} displayName={displayName} userId={userId} />
            </Grid>
            <Box>
              <Typography align='center'>{displayName.split(' ').map(token => {
                if (['cs', 'gy', 'ny', 'sz', 'ty', 'zs'].map(letter => letter).includes(token.slice(0, 2).toLowerCase())) {
                  return token.slice(0, 2)
                } else {
                  return token[0]
                }
              })}</Typography>
            </Box>
          </Grid>
        }
        {!onlyInitials &&
          <Grid container direction='column' justify='center' alignItems='center'>
            <Grid item>
              <AvatarComp classes={classes} theme={theme} displayName={displayName} userId={userId} />
            </Grid>
            <Grid item>
              <Box width={10} />
            </Grid>
            <Grid item>
              <Box width={sidebarWidth} overflow='hidden'>
                <Typography align='center'>{displayName}</Typography>
                <Typography align='center' variant='body2' color='textSecondary'>{status ? status : ''}</Typography>
                <Typography align='center' variant='body2' color='textSecondary'>{nightWatch ? "ÜGYELET" : ''}</Typography>
              </Box>
            </Grid>
          </Grid>
        }
      </Box>
      <Box width={scheduleBoxWidth} minHeight={shiftRowHeight} position='relative' overflow='hidden'>
        {Object.values(rows).length > 0 && Object.keys(rows).map(key => {
          return (
            <Box key={key} width={scheduleBoxWidth} height={shiftRowHeight} position='relative' overflow='hidden'>
              {rows[key]
                .filter(shift => filterShifts(shift))
                .map(shift => {
                  const startTimeDate = new Date(shift.startTime)
                  const queryDayDate = new Date(queryDay)
                  const leftOffsetHours = (startTimeDate.getTime() - new Date(queryDayDate.getUTCFullYear(), queryDayDate.getUTCMonth(), queryDayDate.getUTCDate(), hoursArray[0], 0).getTime()) / 1000 / 60 / 60
                  let backgroundColor
                  if (shift.holiday || shift.nightWatch) backgroundColor = 'darkgrey'
                  else if (!shift.arrived && !shift.finished) backgroundColor = 'lightgrey'
                  else if (shift.arrived && !shift.finished) backgroundColor = '#5788d6'
                  else if (shift.finished) backgroundColor = '#2C5391'
                  return (
                    <Grid item key={`${shift.endTime}#${shift.startTime}#${shift.ticketId}#${shift.tech}`}>
                      <Box
                        position='absolute'
                        left={leftOffsetHours * scheduleBoxWidth / (hoursArray.length - 1)}
                        top={5}
                        height={shiftRowHeight - 10}
                        width={Math.max(10, shift.durationHours * scheduleBoxWidth / (hoursArray.length - 1))}
                        margin={0}
                        borderRadius={5}
                      >
                        {shift.durationHours >= 3 &&
                          <Button
                            fullWidth
                            disabled={ shift.holiday || shift.nightWatch }
                            style={{ backgroundColor, color: 'white', height: '100%' }}
                            onClick={() => history.push(`/ticketek/${shift.ticketId}`)}
                          >
                            {shift.ticketId}
                          </Button>
                        }
                        {shift.durationHours < 3 && shift.durationHours >= 1 &&
                          <Box
                            display='flex'
                            flexDirection='column'
                            justifyContent='center'
                            style={{
                              backgroundColor,
                              color: 'white',
                              height: '100%',
                              borderRadius: 5,
                              userSelect: 'none'
                            }}
                            onClick={() => history.push(`/ticketek/${shift.ticketId}`)}
                          >
                            {shift.ticketId.split('_')[0]}
                          </Box>
                        }
                        {shift.durationHours < 1 &&
                          <Box
                            display='flex'
                            flexDirection='column'
                            justifyContent='center'
                            style={{
                              backgroundColor,
                              color: 'white',
                              height: '100%',
                              borderRadius: 5,
                              userSelect: 'none'
                            }}
                            onClick={() => history.push(`/ticketek/${shift.ticketId}`)}
                          />
                        }
                      </Box>
                    </Grid>
                  )
                })
              }
            </Box>
          )
        })}
      </Box>
      <Grid item>
        <Box width={sidebarWidth}>
          <Typography variant='body2' display='block' style={{ fontWeight: 'bold' }}>{`${shifts.length} ${!onlyInitials ? 'ticket' : ''}`}</Typography>
          <Typography variant='body2' display='block'>{numbers && numbers !== 0 ? `${numbers.toFixed(1)} ${!onlyInitials ? 'óra' : ''}` : ''}</Typography>
          <Typography variant='body2' display='block'>{numbers && numbers !== 0 ? `${(numbers / 8 * 100).toFixed(0)}%` : ''}</Typography>
        </Box>
      </Grid>
    </Grid>
  )
}