import React from 'react'

import Avatar from '@material-ui/core/Avatar'

const baseImageUrl = `https://sspt-avatars.s3.eu-central-1.amazonaws.com`

export const AvatarComp = props => {

  const { classes, theme, displayName, userId } = props

  return (
    <Avatar
      className={classes.avatar}
      alt={displayName}
      src={userId ? `${baseImageUrl}/${userId}.jpg` : null}
      style={{
        backgroundColor: classes.ticketText,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        fontSize: '0.8rem'
      }}>
      {displayName.split(' ').map(token => {
        if (['cs', 'gy', 'ny', 'sz', 'ty', 'zs'].map(letter => letter).includes(token.slice(0, 2).toLowerCase())) {
          return token.slice(0, 2)
        } else {
          return token[0]
        }
      })}
    </Avatar>
  )
}