import React, { Fragment } from 'react'

import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'
import PostAddOutlinedIcon from '@material-ui/icons/PostAddOutlined'
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined'
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined'
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined'
import SwapHorizOutlinedIcon from '@material-ui/icons/SwapHorizOutlined'
import QueueOutlinedIcon from '@material-ui/icons/QueueOutlined'
import BeenhereOutlinedIcon from '@material-ui/icons/BeenhereOutlined'
import NotInterestedOutlinedIcon from '@material-ui/icons/NotInterestedOutlined'

export const TicketingIconChooser = props => {
  return (
    <Fragment>
      {props.event === 'ARRIVE' && <LocationOnOutlinedIcon fontSize={props.size} color='primary' />}
      {props.event === 'WORKSHEET' && <PostAddOutlinedIcon fontSize={props.size} color='primary' />}
      {props.event === 'CONFIRM' && <ScheduleOutlinedIcon fontSize={props.size} color='primary' />}
      {props.event === 'MESSAGE_CLIENT' && <ChatOutlinedIcon fontSize={props.size} color='primary' />}
      {props.event === 'CHANGE_TEAM' && <GroupAddOutlinedIcon fontSize={props.size} color='primary' />}
      {props.event === 'CHANGE_STATUS' && <SwapHorizOutlinedIcon fontSize={props.size} color='primary' />}
      {['NEW_TICKET', 'MODIFIED_TICKET', 'REPLACED_TICKET', 'newticket'].includes(props.event) && <QueueOutlinedIcon fontSize={props.size} color='primary' />}
      {['APPROVE_CLOSE', 'APPROVE_CANCEL'].includes(props.event) && <BeenhereOutlinedIcon fontSize={props.size} color='primary' />}
      {['REJECT_CLOSE', 'REJECT_CANCEL'].includes(props.event) && <NotInterestedOutlinedIcon fontSize={props.size} color='primary' />}
    </Fragment>
  )
}