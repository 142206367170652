export const getStatusName = (statusSelection) => {
  switch (statusSelection) {
    case 'open':
      return 'Nyitott'
    case 'confirmed':
      return 'Megerősített'
    case 'in_progress':
      return 'Folyamatban'
    case 'waiting':
      return 'Várakozik'
    case 'rejected':
      return 'Visszautasított'
    case 'approve':
      return 'Jóváhagyandó'
    default:
      throw new Error('Unknown ticket status.')
  }
}