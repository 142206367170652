export const ticketTypeMap = {
  FAULT: "Hibabejelentés",
	SUPERVISION: "Műszaki felügyelet",
	PLANNED_MAINTENANCE: "TMK",
	INSTALLATION: "Installáció",
	RECONFIGURE: "Átépítés",
	SSPT_REFURBISH: "Felújítás",
	SSPT_MANUFACTURE: "Gyártás",
  SSPT_TRANSPORT: "Szállítás",
	SSPT_MISC: "Egyéb"
}