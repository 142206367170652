import React from 'react'

import Button from '@material-ui/core/Button'
import GetAppIcon from '@material-ui/icons/GetApp'
import { authWrapper, getGetWSSignedUrl } from '../../requests/ticketRequest'

export const WorksheetDownload = props => {

  const { WSUrl, authedUser } = props

  return (
    <Button
      style={{ marginTop: '2rem', marginBottom: '1rem' }}
      variant='outlined'
      color='primary'
      fullWidth={true}
      startIcon={<GetAppIcon />}
      onClick={async () => {
        const signedUrlResponse = await authWrapper(authedUser, getGetWSSignedUrl, WSUrl)
        window.location.replace(signedUrlResponse.data.S3Url)
      }}
    >Munkalap letöltése
    </Button>
  )
}