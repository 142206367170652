import React from 'react'
import { useHistory } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'

import { TicketDetails } from './TicketDetails'
import { CornerIndicator } from './CornerIndicator'

export const TicketCard = (props) => {

  const history = useHistory()

  return (
    <Card align='left' className={props.classes.ticketEventCard} style={{ marginLeft: '1rem', maxWidth: '540px' }}>
      <CardContent style={{ position: 'relative' }}>
        {['PENDING_CLOSE', 'PENDING_CANCEL'].includes(props.item.ticketStatus) &&
          props.authedUser.attributes['custom:company'] === 'SSPT' &&
          !props.item.ssptApproval &&
          < CornerIndicator size={45} theme={props.theme} />
        }
        <Typography
          style={{ paddingLeft: '2rem', fontWeight: 'bold' }}
          variant="subtitle1"
          className={`${props.classes.cardTitle} ${props.classes.ticketEventCardTitle}`}
          gutterBottom
          color='primary'
        >{props.item.ticketId}</Typography>
        <Grid container direction='column' justify='center' alignItems='flex-start' style={{ marginTop: '1rem', paddingLeft: '2rem', paddingRight: '2rem' }} >
          <TicketDetails ticketEvent={props.item} />
          <Typography
            style={{ fontWeight: 'bold' }}
            variant="body2"
            color='textSecondary'
          >Hibaleírás</Typography>
          <Typography
            style={{ marginBottom: '1rem' }}
            variant="body2"
            color='textSecondary'
          >{`${props.item.description.slice(0, 45)}${(props.item.description.length > 45) ? '...' : ''}`}</Typography>
          <Button
            variant='outlined'
            color='primary'
            fullWidth
            disabled={false}
            onClick={() => {
              history.push(`/ticketek/${props.item.ticketId}`)
            }}
          >Részletek
      </Button>
        </Grid>
      </CardContent>
    </Card>
  )
}