import React, { useEffect, Fragment, useState, useContext } from 'react'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { TicketCard } from './Subcomponents/TicketCard'

import { TicketingContext } from '../TicketingContext'

export const Assigned = props => {

  const { errorType, setErrorType, setLoadingSuccess, assignedTickets, tickets, refreshAssigned, refreshTickets, authedUser, classes, theme } = useContext(TicketingContext)

  const [fetchActive, setFetchActive] = useState(false)

  useEffect(() => {
    if (errorType) {
      setLoadingSuccess(true)
    }
  }, [errorType, setLoadingSuccess])

  useEffect(() => {
    try {
      if ((!assignedTickets || !tickets) && !fetchActive) {
        setFetchActive(true)
        refreshAssigned()
      }
    } catch (error) {
      setErrorType(error.message)
    }
  }, [assignedTickets, fetchActive, refreshAssigned, refreshTickets, setErrorType, tickets])

  useEffect(() => {
    if (assignedTickets && tickets && fetchActive) {
      setFetchActive(false)
      // console.log('Assigned tickets:', assignedTickets)
      // console.log('Tickets:', tickets.map(ticket => ticket.ticketId))
    }
  }, [assignedTickets, fetchActive, tickets])

  return (
    <Fragment>
      <Grid container direction='row' justify='center' alignItems='center' style={{ marginTop: '0rem' }}>
        <div style={{ height: 20, width: '100%' }} />
        {assignedTickets &&
          tickets && tickets.length !== 0 &&
          assignedTickets
            .filter(assignedTicket => tickets.map(ticket => ticket.ticketId).includes(assignedTicket))
            .length === 0 &&
          <Grid container direction='column' justify='flex-start' align='left' item xs={10}>
            <Typography variant='h5' align='left' color='primary'
              style={{ marginBottom: '1rem', marginTop: '3rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              {`Kedves ${authedUser.attributes.name.split(' ').slice(-1)},`}
            </Typography>
            <Typography variant='subtitle1' align='left'
              style={{ marginBottom: '0rem', marginTop: '1rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              Jelenleg nincs hozzád rendelt ticket. Kérlek egyeztess a szervízvezetővel, hogy min tudnál dolgozni!
          </Typography>
            <Typography variant='subtitle1' align='left'
              style={{ marginBottom: '0rem', marginTop: '2rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              Üdvözlettel,
          </Typography>
            <Typography variant='subtitle1' align='left'
              style={{ marginBottom: '1rem', marginTop: '0rem', paddingRight: '2rem', fontStyle: 'bold' }}>
              SSPT Ticketing Bot
          </Typography>
          </Grid>
        }
        {assignedTickets && assignedTickets.length !== 0 &&
          tickets && tickets.length !== 0 &&
          assignedTickets
            .filter(assignedTicket => tickets.map(ticket => ticket.ticketId).includes(assignedTicket))
            .sort((a, b) => (a > b) ? 1 : -1)
            .map((item, index) => {
              return (
                <Fragment key={index}>
                  { tickets.length > 0 &&
                    <Grid item xs={12} md={6} lg={6} xl={4}>
                      <TicketCard item={tickets.find(ticket => ticket.ticketId === item)} authedUser={authedUser} classes={classes} theme={theme}></TicketCard>
                    </Grid>
                  }
                </Fragment>
              )
            })
        }
      </Grid>
    </Fragment>
  )
}