import React, { Fragment, useContext, useEffect } from 'react'

import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'

import { DashboardCard } from './Subcomponents/DashboardCard'
import { EventPageHeader } from './Subcomponents/EventPageHeader'

import { TicketingContext } from '../TicketingContext'
import { useParams } from 'react-router-dom'

export const DashBoard = props => {

  const { classes, optionDetails, ticketDetails, newTicketUrl } = useContext(TicketingContext)
  const { ticketId } = useParams()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <Fragment>
      <EventPageHeader ticketId={ticketId} options={ticketDetails[ticketId]?.options} />
      { ticketDetails[ticketId]?.options &&
        <Fade in={true} timeout={{ enter: 500 }}>
          <Grid container justify='center' alignItems='center' direction='row' wrap='wrap' spacing={2}>
            {optionDetails.filter(item => ticketDetails[ticketId]?.options.events.includes(item.event) && item.event !== 'modadmin').map(option => (
              <Grid item key={option.title} xs={11} sm={8} lg={5} xl={4} align='center'>
                <DashboardCard history={props.history} newTicketUrl={newTicketUrl} option={option} classes={classes} options={ticketDetails[ticketId]?.options} ticketId={ticketId} />
              </Grid>
            ))}
          </Grid>
        </Fade>
      }
    </Fragment>
  )
}