import React, { useEffect, useState, Fragment, useContext, useLayoutEffect } from 'react'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Switch from '@material-ui/core/Switch'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import RefreshIcon from '@material-ui/icons/Refresh'
import Brightness3Icon from '@material-ui/icons/Brightness3'
import Brightness7Icon from '@material-ui/icons/Brightness7'

import { ssptUsers } from '../data/ssptUsers'
import { ShiftRow } from './Subcomponents/ShiftRow'
import { getFormattedlocalDate } from '../components/helper/getFormattedLocalDate'
import { getStringFromDate } from '../components/helper/getStringFromDate'
import { getWeekDay } from '../components/helper/getWeekDay'

import { TicketingContext } from '../TicketingContext'


export const Shifts = props => {

  const { errorType, setErrorType, setLoadingSuccess, queryDay, setQueryDay, hoursArray, kDayHourArray,
    toggleNightShift, shifts, getShifts, holidays, nightWatches, classes, theme } = useContext(TicketingContext)

  const [scheduleBoxWidth, setScheduleBoxWidth] = useState(window.innerWidth * 0.7)
  const [onlyInitials, setOnlyInitials] = useState(window.innerWidth < 1000)
  const [sidebarWidth, setSideBarWidth] = useState(window.innerWidth * 0.125)
  const [scrollPositionY, setScrollPositionY] = useState(0)
  const [fetchActive, setFetchActive] = useState({})

  useLayoutEffect(() => {
    const updateSizes = () => {
      setScheduleBoxWidth(window.innerWidth * 0.7)
      setSideBarWidth(window.innerWidth * 0.125)
      if (window.innerWidth < 1000) {
        setOnlyInitials(true)
      } else {
        setOnlyInitials(false)
      }
    }
    window.addEventListener('resize', updateSizes)
    return () => window.removeEventListener('resize', updateSizes)
  })

  useLayoutEffect(() => {
    const updateFixed = () => {
      setScrollPositionY(window.scrollY)
    }
    window.addEventListener('scroll', updateFixed)
    return () => window.removeEventListener('scroll', updateFixed)
  })

  useEffect(() => {
    if (errorType) {
      setLoadingSuccess(true)
    }
  }, [errorType, setLoadingSuccess])

  useEffect(() => {
    try {
      if (!shifts[queryDay] && !fetchActive[queryDay]) {
        setFetchActive({ ...fetchActive, [queryDay]: true })
        getShifts(queryDay)
      }
    } catch (error) {
      setErrorType(error.message)
    }
  }, [shifts, queryDay, fetchActive, getShifts, setErrorType])
  
  useEffect(() => {
    if (shifts[queryDay] && holidays[queryDay] && nightWatches[queryDay] && fetchActive[queryDay]) {
      setFetchActive({ ...fetchActive, [queryDay]: false })
    }
  }, [shifts, queryDay, fetchActive, holidays, nightWatches])

  return (
    <Fragment>
      <Grid container direction='column' justify='flex-start' alignItems='center' style={{ backgroundColor: 'white' }}>
        <Box width='100%' position='static' top={45} display='flex' flexDirection='column' alignItems='center' zIndex={1} style={{ backgroundColor: 'white' }}>
          <Box height={70} width='100%' display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
            {!onlyInitials &&
              <Box flex={2}></Box>
            }
            {onlyInitials &&
              <Box position='relative' width='50%' left={5} top={0} display='flex' flexDirection='row' justifyContent='center' flex={1}>
                <ToggleButtonGroup
                  value={hoursArray ? hoursArray === kDayHourArray : true}
                  exclusive
                  onChange={toggleNightShift}
                >
                  <ToggleButton value={true}>
                    <Brightness7Icon color='secondary' fontSize='small' />
                  </ToggleButton>
                  <ToggleButton value={false}>
                    <Brightness3Icon color='secondary' fontSize='small' />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            }
            <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' flex={1}>
              <RemoveIcon fontSize='default' color='inherit' onClick={() => {
                const newQueryDayAsDate = new Date(new Date(queryDay).getTime() - 1000 * 60 * 60 * 24)
                setQueryDay(getStringFromDate(newQueryDayAsDate))
              }} />
              <Box width={10}></Box>
              <Box display='flex' flexDirection='column' justifyContent='flex-end' alignItems='center'>
                <Typography style={{ fontWeight: 'bold' }}>{onlyInitials ? `${new Date(queryDay).getFullYear()}.${(new Date(queryDay).getMonth() + 1 < 10) ? `0${new Date(queryDay).getMonth() + 1}` : `${new Date(queryDay).getMonth() + 1}`}.${new Date(queryDay).getDate()}` : getFormattedlocalDate(queryDay)}</Typography>
                <Typography>{onlyInitials ? getWeekDay(new Date(queryDay)).slice(0, 3) : getWeekDay(new Date(queryDay))}</Typography>
              </Box>
              <Box width={10}></Box>
              <AddIcon fontSize='default' color='inherit' onClick={() => {
                const newQueryDayAsDate = new Date(new Date(queryDay).getTime() + 1000 * 60 * 60 * 24)
                setQueryDay(getStringFromDate(newQueryDayAsDate))
              }} />
            </Box>
            {!onlyInitials &&
              <Box position='relative' width='50%' right={5} top={0} display='flex' flexDirection='row' justifyContent='center' alignItems='center' flex={1}>
                <Brightness7Icon color='secondary' fontSize='small' />
                <Switch
                  checked={hoursArray ? hoursArray !== kDayHourArray : false}
                  color='primary'
                  onClick={toggleNightShift}
                />
                <Brightness3Icon color='secondary' fontSize='small' />
              </Box>
            }
            <Box position='relative' width='50%' right={10} top={0} display='flex' flexDirection='row' justifyContent='center' flex={1}>
              <Button
                variant='outlined'
                color='primary'
                startIcon={onlyInitials ? null : <RefreshIcon />}
                onClick={() => getShifts(queryDay)}
              >
                {onlyInitials ? <RefreshIcon /> : 'Frissítés'}
              </Button>
            </Box>
          </Box>
          <Box height={50} width='100%' position={scrollPositionY < 65 ? 'static' : 'fixed'} top={scrollPositionY < 65 ? null : 45} style={{ backgroundColor: '#FFFFFFEE' }}>
            <Grid container direction='row' justify='center' alignItems='center' style={{ height: 50 }}>
              {hoursArray.map(item => {
                return (
                  <div key={item} style={{
                    width: scheduleBoxWidth / (hoursArray.length - 1),
                    textAlign: 'center',
                  }}>{item}</div>
                )
              })
              }
            </Grid>
            <Divider variant='fullWidth' />
          </Box>
        </Box>
        <Box height={scrollPositionY > 65 ? 50 : 0} />
        {shifts[queryDay] && Object.keys(shifts[queryDay]).length > 0 && Object.keys(shifts[queryDay])
          .sort((a, b) => {
            if (ssptUsers.find(item => item.email.split('@')[0] === a).status) {
              return 1
            } else if (ssptUsers.find(item => item.email.split('@')[0] === b).status) {
              return -1
            } else if (a > b) {
              return 1
            } else {
              return -1
            }
          })
          .map(tech => (
            <Grid item key={tech}>
              <ShiftRow
                classes={classes}
                theme={theme}
                hoursArray={hoursArray}
                scheduleBoxWidth={scheduleBoxWidth}
                onlyInitials={onlyInitials}
                sidebarWidth={sidebarWidth}
                displayName={ssptUsers.find(item => item.email.split('@')[0] === tech).displayName}
                status={ssptUsers.find(item => item.email.split('@')[0] === tech).status?.text}
                userId={ssptUsers.find(item => item.email.split('@')[0] === tech).noPhoto ? null : tech}
                tech={tech}
                shifts={shifts[queryDay][tech]}
                holiday={holidays[queryDay].find(holiday => holiday.tech === tech)}
                nightWatch={nightWatches[queryDay]?.tech === tech}
                queryDay={queryDay}
              />
              <Divider variant='middle' />
            </Grid>
          ))
        }
        <Box height={100} />
      </Grid>
    </Fragment>
  )
}